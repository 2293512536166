import React from 'react'

import { classNames } from '../../util/class-names'

interface Props {
  className?: string
  justify?: 'center' | 'flex-end' | 'flex-start' | 'space-between'
}

export const CardFooter: React.FC<Props> = ({ className, justify, ...props }) => {
  const params = {
    style: {
      display: 'flex',
      justifyContent: justify
    }
  }
  return <div className={classNames('card-footer', className)} {...params} {...props} />
}
