export enum SolarOrientation {
  NORTH = 'N',
  NORTHEAST = 'NE',
  EAST = 'E',
  SOUTHEAST = 'SE',
  SOUTH = 'S',
  SOUTHWEST = 'SO',
  WEST = 'O',
  NORTHWEST = 'NO'
}

export function getSolarOrienationDescription(value: SolarOrientation) {
  switch (value) {
    case SolarOrientation.NORTH:
      return 'Norte'
    case SolarOrientation.NORTHEAST:
      return 'Nordeste'
    case SolarOrientation.EAST:
      return 'Leste'
    case SolarOrientation.SOUTHEAST:
      return 'Sudeste'
    case SolarOrientation.SOUTH:
      return 'Sul'
    case SolarOrientation.SOUTHWEST:
      return 'Sudoeste'
    case SolarOrientation.WEST:
      return 'Oeste'
    case SolarOrientation.NORTHWEST:
      return 'Noroeste'
  }
}

// export declare namespace SolarOrientation {
//   export function toString(value: SolarOrientation) {
//     switch (value) {
//       case SolarOrientation.NORTH:
//         return 'Norte'
//       case SolarOrientation.NORTHEAST:
//         return 'Nordeste'
//       case SolarOrientation.EAST:
//         return 'Leste'
//       case SolarOrientation.SOUTHEAST:
//         return 'Sudeste'
//       case SolarOrientation.SOUTH:
//         return 'Sul'
//       case SolarOrientation.SOUTHWEST:
//         return 'Sudoeste'
//       case SolarOrientation.WEST:
//         return 'Oeste'
//       case SolarOrientation.NORTHWEST:
//         return 'Noroeste'
//     }
//   }
// }
