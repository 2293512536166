import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, FormGroup, FormText } from 'shared/form'
import { Button } from 'shared/ui/button/Button'
import { Card } from 'shared/ui/card/Card'
import { toFormData } from 'shared/util/form-data'

import classes from './password.module.scss'

interface PasswordState {
  password?: string
  repeated?: string
}

export const Password: React.FC = () => {
  const history = useHistory()

  const [state, setState] = useState<PasswordState>({ password: undefined, repeated: undefined })

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const init: RequestInit = {
      method: 'POST',
      body: toFormData({ password: state.password })
    }

    fetch('/api/newpassword', init).then(() => {
      history.push('/')
    })
  }

  return (
    <Card className={classes.card}>
      <h2>Nova senha</h2>
      <Form className={classes.form} onSubmit={onSubmit}>
        <FormGroup>
          <FormText
            type="password"
            placeholder="Nova senha"
            required
            value={state.password}
            message="Preencha uma senha válida."
            onChange={password => setState(state => ({ ...state, password }))}
          />
          <FormText
            type="password"
            placeholder="Repita a nova senha"
            required
            value={state.repeated}
            pattern={state.password}
            message="Repita a senha."
            onChange={repeated => setState(state => ({ ...state, repeated }))}
          />
        </FormGroup>
        <Button>Salvar</Button>
      </Form>
    </Card>
  )
}
