import React, { useCallback, useEffect, useState } from 'react'

import { SessionProductIcon } from 'shared/components/sessions/SessionProductIcon'
import { useSessions } from 'shared/hooks/data/session'
import { useRefState } from 'shared/hooks/util'
import { Table, TableBody, TableColumn, TableHeader, TablePaginator, TableRow } from 'shared/ui/table'
import { formatISO } from 'shared/util/date'
import { parseAddress } from 'shared/util/google-maps'

import { useAuth } from 'components/auth/AuthContext'

import { SessionListColumnAccept } from './SessionListColumnAccept'
import { SessionListColumnID } from './SessionListColumnID'

interface SessionListTableProps {
  className?: string
  filter?: { [key: string]: any }
}

export const SessionListTable: React.FC<SessionListTableProps> = ({ className, filter }) => {
  const auth = useAuth()
  const filterRef = useRefState(filter)
  const [meta, setMeta] = useState<Http.ResponseMeta>()
  const [paging, setPaging] = useState<Http.Pagination>({ page: 1, length: 50 })
  const [params, setParams] = useState(filter)
  const handleMeta = useCallback(meta => setMeta(meta), [])
  const sessions = useSessions(params, paging, handleMeta)
  useEffect(() => setParams(filterRef), [filterRef])
  return (
    <Table className={className} hover strip>
      <TableHeader>
        <TableColumn align="center">ID</TableColumn>
        <TableColumn align="center" size={3}>
          Endereço
        </TableColumn>
        <TableColumn align="center" size={2}>
          Cliente
        </TableColumn>
        <TableColumn align="center">Data do ensaio</TableColumn>
        <TableColumn align="center">Data da finalização</TableColumn>
        <TableColumn align="center">Entregáveis</TableColumn>
        {auth?.hasWorkTypeLocal === false && <TableColumn align="center">Aceitar</TableColumn>}
      </TableHeader>
      <TableBody>
        {sessions?.map(session => {
          const address = parseAddress(session.address?.address_components)
          return (
            <TableRow key={session.id}>
              <TableColumn align="center">
                <SessionListColumnID session={session} />
              </TableColumn>
              <TableColumn align="center" size={3}>
                <div>
                  {address.get('street')}
                  {address.has('number') && `, ${address.get('number')}`}
                  {session.vertical_data?.unit && ` - ${session.vertical_data?.unit}`}
                </div>
                {session.vertical_data?.complement && <div>{session.vertical_data.complement}</div>}
                <div>
                  {address.get('neighborhood')}, {address.get('city')}/{address.get('state')}
                </div>
              </TableColumn>
              <TableColumn align="center" size={2}>
                <div>{session.client.name}</div>
                {session.vertical_data?.reference && <strong>{session.vertical_data?.reference}</strong>}
              </TableColumn>
              <TableColumn align="center">{formatISO(session.scheduled_for!, 'dd/MM/yyyy HH:mm')}</TableColumn>
              <TableColumn align="center">
                {session.delivered_at ? formatISO(session.delivered_at, 'dd/MM/yyyy HH:mm') : '-'}
              </TableColumn>
              <TableColumn align="center" dir="row" justify="space-around">
                {session.products
                  ?.sort((a, b) => b.status - a.status)
                  .filter(({ item }) =>
                    auth?.provider?.items.find(({ deliverable, id }) => id === item.id && deliverable)
                  )
                  .filter(({ item }, i, products) => i === products.findIndex(p => p.item.icon === item.icon))
                  .map(product => (
                    <SessionProductIcon key={product.id} url={product.item.icon} status={product.status} />
                  ))}
              </TableColumn>
              {auth?.hasWorkTypeLocal === false && (
                <TableColumn align="center">
                  <SessionListColumnAccept onChange={() => setParams({ ...params })} session={session} />
                </TableColumn>
              )}
            </TableRow>
          )
        })}
      </TableBody>
      <TablePaginator meta={meta} onChange={(page, length) => setPaging({ page, length })} />
    </Table>
  )
}
