import React, { useEffect, useMemo, useRef } from 'react'
import ReactDOM from 'react-dom'

import { classNames } from 'shared/util/class-names'

import { ModalContainer, ModalContainerRef } from './ModalContainer'

// import classes from './Modal.module.scss'
interface ModalProps {
  backDropClick?: (_: React.MouseEvent) => void
  className?: string
  contentClassName?: string
  size?: 'sm' | 'lg' | 'xl'
}

export type Component<P> = React.FC<P>

export function useModal<P extends {}>(Component: Component<P>, modalProps?: P) {
  const container = useRef<ModalContainerRef<object>>(null)
  const props = useRef<P>(modalProps || ({} as P))
  const child = useMemo(() => <Component {...props.current} />, [Component])
  useEffect(() => {
    props.current = modalProps || ({} as P)
  }, [modalProps])
  useEffect(() => {
    const node = document.body.appendChild(document.createElement('div'))
    ReactDOM.render(<ModalContainer children={child} ref={container} />, node)
    return () => {
      ReactDOM.unmountComponentAtNode(node)
      node.remove()
    }
  }, [Component])
  return container
}

export const Modal: React.FC<ModalProps> = ({ className, contentClassName, size, ...props }) => {
  const dialogClass = classNames(
    className,
    'modal-dialog',
    'modal-dialog-centered',
    'modal-dialog-scrollable',
    size && `modal-${size}`
  )
  const contentClass = classNames('modal-content', contentClassName)
  const handleContentClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement
    if (!target.classList.contains('btn-close')) {
      event.stopPropagation()
    }
  }
  return (
    <div className={dialogClass}>
      <div className={contentClass} onClick={handleContentClick} {...props} />
    </div>
  )
}
