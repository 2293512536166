import React, { useState } from 'react'

import { Button } from 'shared/bootstrap/button'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/bootstrap/modal'
import { FormGroup } from 'shared/form/FormGroupNew'
import { InputTextArea } from 'shared/form/InputTextArea'
import { toFormData } from 'shared/util/form-data'

interface Props {}

export const FinantialReport: React.FC<Props> = () => {
  const [value, setValue] = useState({ report: '' })
  const handleSubmit: React.MouseEventHandler = event => {
    event.persist()
    event.currentTarget.setAttribute('disabled', 'disabled')
    const body = toFormData({ type: 1, ...value })
    fetch(`/api/reports`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          window.location.reload()
        } else {
          throw new Error()
        }
      })
      .catch(() => {
        alert('Falha ao salvar informações')
        event.currentTarget.removeAttribute('disabled')
      })
  }
  return (
    <Modal>
      <ModalHeader title="Solicitar correção de ensaios realizados" />
      <ModalBody>
        <FormGroup label="Informações">
          <InputTextArea rows={5} onChange={report => setValue(value => ({ ...value, report }))} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button disabled={value.report === ''} onClick={handleSubmit}>
          ENVIAR
        </Button>
      </ModalFooter>
    </Modal>
  )
}
