import React from 'react'

import { classNames } from '../../util/class-names'

import classes from './Card.module.scss'

interface CardProps {
  className?: string
  margin?: 'bottom' | 'top'
  shadow?: boolean
}

export const Card: React.FC<CardProps> = ({ className, margin, shadow, ...props }) => {
  const klass = classNames('card', margin && classes[`margin-${margin}`], shadow && classes.shadow, className)
  return <div className={klass} {...props} />
}
