import React, { useState } from 'react'

import { useDebouncedCallback } from 'shared/hooks/util'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'

import { SessionListFilter } from './components/SessionListFilter'
import { SessionListTable } from './components/SessionListTable'

import classes from './SessionList.module.scss'

export const SessionList: React.FC = () => {
  const [filter, setFilter] = useState<object>({})
  const handleFilter = useDebouncedCallback((filter: object) => setFilter({ filter }), [], 400)
  return (
    <Container fluid="xl">
      <Breadcrumb>
        <BreadcrumbItem>Ensaios</BreadcrumbItem>
      </Breadcrumb>
      <Card className={classes.card} shadow>
        <p className={classes.title}>
          <strong>Filtrar ensaios</strong>
        </p>
        <SessionListFilter onChange={handleFilter} />
      </Card>
      <Card className={classes.content} shadow>
        <SessionListTable className={classes.table} filter={filter} />
      </Card>
    </Container>
  )
}
