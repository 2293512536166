import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useAuth } from './components/auth/AuthContext'
import { Footer } from './components/Footer'
import { Navbar } from './components/Navbar'

import { Profile } from 'pages/profile'
import { Recover } from 'pages/recover'
import { Done } from 'pages/recover/Done'

import { Login } from './pages/auth/Login'
import { Dashboard } from './pages/dashboard/Dashboard'
import { Finantial } from './pages/finantial/Finantial'
import { Sessions } from './pages/sessions/Sessions'
import { Calendar } from 'pages/calendar/Calendar'

import classes from './Routes.module.scss'

export const Routes: React.FC = () => {
  const auth = useAuth()
  if (auth === undefined) {
    // LOADER
    return null
  } else {
    return auth ? (
      <div className={classes.wrapper}>
        <Navbar />
        <div className={classes.content}>
          <Switch>
            {auth.hasWorkTypeLocal && <Route path="/dashboard" component={Dashboard} />}
            <Route path="/finantial" component={Finantial} />
            <Route path="/profile" component={Profile} />
            <Route path="/sessions" component={Sessions} />
            <Route path="/calendar" component={Calendar} />
            <Redirect to={auth.hasWorkTypeLocal ? '/dashboard' : '/sessions'} />
          </Switch>
          <Footer />
        </div>
      </div>
    ) : (
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/recover/done" component={Done} />
        <Route path="/recover" component={Recover} />
        <Redirect to="/login" />
      </Switch>
    )
  }
}
