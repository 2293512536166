import { useEffect, useState } from 'react'

export function useSessions() {
  const [sessions, setSessions] = useState<any[]>()
  useEffect(() => {
    fetch(`/api/sessions`)
      .then(response => response.json())
      .then(({ data }) => setSessions(data))
  }, [])
  return sessions
}

export function useSession(ref: Model.Key) {
  const [session, setSession] = useState<Resource.SessionResource>()
  useEffect(() => {
    fetch(`/api/sessions/${ref.id}`)
      .then(response => response.json())
      .then(({ data }) => setSession(data))
  }, [ref])
  return session
}
