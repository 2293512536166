import React, { useEffect, useState } from 'react'
import { addMonths, endOfMonth, isThisMonth, startOfMonth, subMonths } from 'date-fns'

import { Card, CardBody } from 'shared/bootstrap/card'
import { FormUpload } from 'shared/form/FormUpload'
import { useInvoices } from 'shared/hooks/data/invoice'
import { Icon } from 'shared/ui/icon'
import { toFormData } from 'shared/util'
import { formatDate } from 'shared/util/date'

import classes from './FinantialUploadInvoice.module.scss'

const getParams = (date: Date) => ({
  filter: {
    issued_at: [formatDate(startOfMonth(date)), formatDate(endOfMonth(date))]
  }
})

let skipped = false

export const FinantialUploadInvoice: React.FC = () => {
  const [date, setDate] = useState(new Date())
  const [params, setParams] = useState(getParams(date))
  const invoices = useInvoices(params)
  const handleChange = (files: File[]) => {
    const requests = files.map(file => {
      const body = toFormData({ file, issued_at: formatDate(date) })
      return fetch(`/api/invoices`, { body, method: 'POST' })
    })
    Promise.allSettled(requests).finally(() => {
      setParams({ ...params })
    })
  }
  useEffect(() => {
    if (skipped) {
      setParams(getParams(date))
    } else {
      skipped = true
    }
  }, [date])
  return (
    <Card>
      <CardBody>
        <div className={classes.header}>
          <button type="button" onClick={() => setDate(date => subMonths(date, 1))}>
            <Icon.AngleLeft />
          </button>
          <span className={classes.date}>{formatDate(date, "MMMM 'de' yyyy")}</span>
          <button type="button" disabled={isThisMonth(date)} onClick={() => setDate(date => addMonths(date, 1))}>
            <Icon.AngleRight />
          </button>
        </div>
        <FormUpload accept=".pdf" multiple onChange={handleChange}>
          <span>Fazer upload de notas fiscais de serviço (formato PDF) de até 1 MB</span>
        </FormUpload>
        {invoices?.map(invoice => (
          <div key={invoice.id}>
            <a href={invoice.file.url} rel="noopener noreferrer" target="_blank">
              {invoice.file.name} - {formatDate(invoice.issued_at, 'dd/MM/yyyy') || '-'}
            </a>
          </div>
        ))}
      </CardBody>
    </Card>
  )
}
