import React from 'react'

import classes from './Footer.module.scss'

const date = new Date()

export const Footer = () => (
  <footer className={classes.footer}>
    <p className={classes.text}>
      © {date.getFullYear()} BIRDIE LTDA.
    </p>
  </footer>
)
