import { format as dateFnsFormat, formatDistanceToNow, isValid, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

const options = {
  locale: ptBR
}

export function toDate(value?: Date | null | string) {
  if (value) {
    const date = typeof value === 'string' ? parseISO(value) : value
    if (isValid(date)) {
      return date
    }
  }
}

export function formatISO(value: Date | string, format: string) {
  const date = toDate(value)
  return date && dateFnsFormat(date, format, options)
}

const DATE_STRING = 'yyyy-MM-dd'
const DATE_TIME_STRING = 'dd/MM/yyyy HH:mm'

export function formatDate(date?: Date | string | null, formatString = DATE_STRING) {
  if (date) {
    return formatISO(date, formatString)
  }
}

export function formatDateTimeString(date: Date | string, formatString = DATE_TIME_STRING) {
  return formatISO(date, formatString)
}

export function formatDistanceNow(value?: Date | string) {
  const date = toDate(value)
  return date && formatDistanceToNow(date, { locale: ptBR })
}

/** @deprecated */
export const format = formatISO
