import React, { createElement, useState } from 'react'

import { Card, CardBody, CardFooter, CardHeader } from 'shared/bootstrap/card'
import { useModal } from 'shared/bootstrap/modal'
import { SessionProductIcon } from 'shared/components/sessions/SessionProductIcon'
import { Item } from 'shared/enum/item'
import { ServiceProductSessionStatus } from 'shared/enum/service-product-session'
import { FormTextArea } from 'shared/form/FormTextArea'
import { InputAutocomplete } from 'shared/form/InputAutocomplete'
import { ProviderDataSource } from 'shared/hooks/source/ProviderDataSource'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon'
import { formatDateTimeString, formatISO } from 'shared/util/date'
import { toFormData } from 'shared/util/form-data'

import { SessionDeliverableBlueprints } from './SessionDeliverableBlueprints'
import { SessionDeliverableCaptureTour } from './SessionDeliverableCaptureTour'
import { SessionDeliverableEditPhotos } from './SessionDeliverableEditPhotos'
import { SessionDeliverableText } from './SessionDeliverableText'
import { SessionDeliverableTour } from './SessionDeliverableTour'
import { SessionDeliverableUrl } from './SessionDeliverableUrl'
import { SessionDeliverableUrlList } from './SessionDeliverableUrlList'
import { SessionDeliverableVideos } from './SessionDeliverableVideos'
import { SessionDeliverableRateModal } from './components/SessionDeliverableRateModal'

import classes from './SessionDeliverables.module.scss'

function getComponent(
  item: Item | number,
  category: 'admin' | 'client' | 'provider',
  product: Resource.SessionResourceProduct<Resource.SessionDeliverableItem>,
  session: Resource.SessionResource
) {
  const props = { category, session }
  switch (item) {
    case Item.BLUEPRINTS:
      return createElement(SessionDeliverableBlueprints, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableBlueprint>,
        ...props
      })
    case Item.CAPTURE_BLUEPRINT:
      return createElement(SessionDeliverableUrlList, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableUrl>,
        ...props
      })
    case Item.CAPTURE_PHOTOS:
    case Item.CAPTURE_TOUR_PHOTOS_PHOTOS:
      return createElement(SessionDeliverableUrl, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableUrl>,
        ...props
      })
    case Item.CAPTURE_TOUR:
    case Item.CAPTURE_TOUR_INSPECT:
    case Item.CAPTURE_TOUR_PHOTOS_TOUR:
      return createElement(SessionDeliverableCaptureTour, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableUrl>,
        ...props
      })
    case Item.COPYWRITING:
      return createElement(SessionDeliverableText, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableText>,
        ...props
      })
    case Item.CAPTURE_PHOTOS_PREMIUM:
    case Item.EDIT_PHOTOS:
    case Item.EDIT_TOUR:
    case Item.EDIT_TOUR_PHOTOS:
      return createElement(SessionDeliverableEditPhotos, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverablePhoto>,
        ...props
      })
    case Item.PRESENTATION_TOUR:
    case Item.PRESENTATION_TOUR_INSPECT:
    case Item.PRESENTATION_TOUR_PHOTOS:
      return createElement(SessionDeliverableTour, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableTour>,
        ...props
      })
    case Item.VIDEOS:
    case Item.VIDEOS_PREMIUM:
      return createElement(SessionDeliverableVideos, {
        product: product as Resource.SessionResourceProduct<Resource.SessionDeliverableVideo>,
        ...props
      })
    default:
      console.log(new Error(`Component for item id ${item} not found`))
      return null
  }
}

interface SessionDeliverablesProps {
  className?: string
  category: 'admin' | 'client' | 'provider'
  session: Resource.SessionResource
}

function getClientOrientation(
  session: Resource.SessionResource,
  product: Resource.SessionResourceProduct<Resource.SessionDeliverableItem>
) {
  return session.client.items?.find(({ id }) => id === product.item.id)?.pivot.orientation
}

export const SessionDeliverables: React.FC<SessionDeliverablesProps> = ({ className, session, category }) => {
  const deliverableRateModal = useModal(SessionDeliverableRateModal, { session })
  const [orientations, setOrientations] = useState(() => {
    return new Map<number, string>(session.products.map(p => [p.item.id, p.orientation || '']))
  })
  const [providers, setProviders] = useState(new Map<number, number>())
  const handleDeliver = (sessionProductId: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    const body = toFormData({ status: ServiceProductSessionStatus.DELIVERED })
    fetch(`/api/sessions/${session.id}/${sessionProductId}`, { body, method: 'POST' })
      .then(() => window.location.reload())
      .catch(() => alert('Falha ao realizar entrega'))
  }
  const handleProvider = (productId: number) => (providerId: number | string) => {
    setProviders(providers => {
      const product = session.products.find(p => p.id === productId)
      const value = new Map(providers)
      if (product?.provider.id === providerId) {
        value.delete(productId)
      } else {
        value.set(productId, Number(providerId))
      }
      return value
    })
  }
  const handleProviderSave = (productId: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    const providerId = providers.get(productId)
    if (providerId) {
      const body = toFormData({ provider_id: providerId })
      fetch(`/api/sessions/${session.id}/${productId}`, { body, method: 'POST' })
        .then(() => window.location.reload())
        .catch(() => alert('Falha ao atualizar fornecedor'))
    }
  }
  const handleOrientation = (item: Pick<Model.Item, 'id'>) => (orientation: string) => {
    setOrientations(orientations => new Map(orientations.set(item.id, orientation)))
  }
  const handleOrientationSave = (product: Resource.SessionResourceProduct) => (event: React.MouseEvent) => {
    event.preventDefault()
    const body = toFormData({ orientation: orientations.get(product.item.id) || null })
    fetch(`/api/sessions/${session.id}/${product.id}`, { body, method: 'POST' })
      .then(() => window.location.reload())
      .catch(() => alert('Falha ao atualizar orientações'))
  }
  const handleRate = (product: Resource.SessionResourceProduct) => (event: React.MouseEvent) => {
    event.preventDefault()
    deliverableRateModal.current?.open({ product })
  }
  return (
    <div className={className}>
      {session.products.map(product => {
        const orientationsProps = {
          placeholder: getClientOrientation(session, product),
          value: orientations.get(product.item.id)
        }
        const orientation = orientationsProps.placeholder || orientationsProps.value
        if (product.item.client_visible === false && category === 'client') {
          return null
        }
        return (
          <Card key={product.id} className={classes.card} shadow>
            <CardHeader className={classes.header}>
              <SessionProductIcon url={product.item.icon} />
              <h2 className={classes.title}>{product.item.label}</h2>
              {product.status === ServiceProductSessionStatus.DELIVERED
                ? product.delivered_at && (
                    <div>
                      {category !== 'client' && <div>Entregue por: {product.delivered_by.name}</div>}
                      <div>Entregue em: {formatISO(product.delivered_at, 'dd/MM/yyyy HH:mm')}</div>
                    </div>
                  )
                : category !== 'client' &&
                  product.item.deliverable && <Button onClick={handleDeliver(product.id)}>FINALIZAR</Button>}
            </CardHeader>
            {category === 'provider' && orientation && product.item.orientation && (
              <CardHeader className={classes.orientations}>
                <div className={classes.info}>
                  <Icon.Bell className={classes.icon} size="2x" />
                  <h4 className={classes.title}>ORIENTAÇÕES</h4>
                  <p className={classes.text}>{orientation}</p>
                </div>
              </CardHeader>
            )}
            {category === 'admin' && (
              <CardHeader className={classes.orientations}>
                <div className={classes.form}>
                  <h4>ORIENTAÇÕES AO FORNECEDOR</h4>
                  <Button size="sm" outline onClick={handleOrientationSave(product)}>
                    SALVAR
                  </Button>
                  <FormTextArea onChange={handleOrientation(product.item)} {...orientationsProps} />
                </div>
              </CardHeader>
            )}
            {category === 'admin' && (
              <CardHeader className={classes.rating}>
                <h4>AVALIAÇÃO</h4>
                {product.rate ? (
                  <div className={classes.rate}>
                    <div className={classes.stars}>
                      {Array.from({ length: product.rate }).map((_, i) => (
                        <Icon.Star key={i} />
                      ))}
                    </div>
                    <div className={classes.date}>
                      {product.rated_at ? formatDateTimeString(product.rated_at) : '-'}
                    </div>
                    {product.rate_description && <div className={classes.description}>{product.rate_description}</div>}
                  </div>
                ) : (
                  <div className={classes.rate}>
                    <Button size="sm" outline onClick={handleRate(product)}>
                      AVALIAR
                    </Button>
                  </div>
                )}
              </CardHeader>
            )}
            <CardBody>
              {category === 'client' && product.status !== ServiceProductSessionStatus.DELIVERED ? (
                <div className={classes.processing}>
                  <div className={classes.icon}>
                    <Icon.Hourglass />
                  </div>
                  <p className={classes.text}>Este material está sendo processado e em breve estará disponível</p>
                </div>
              ) : (
                getComponent(product.item.id, category, product, session)
              )}
            </CardBody>
            {category === 'admin' && (
              <CardFooter>
                <div className={classes.provider}>
                  <div className={classes.name}>
                    Fornecedor: <strong>{product.provider.name || '-'}</strong>
                  </div>
                  <span>Alterar fornecedor:</span>
                  <ProviderDataSource itemId={product.item.id}>
                    <InputAutocomplete className={classes.input} onChange={handleProvider(product.id)} />
                  </ProviderDataSource>
                  <Button
                    size="sm"
                    outline
                    disabled={!providers.has(product.id)}
                    onClick={handleProviderSave(product.id)}
                  >
                    SALVAR
                  </Button>
                </div>
              </CardFooter>
            )}
          </Card>
        )
      })}
    </div>
  )
}
