import React, { useContext } from 'react'

interface TableProps {
  className?: string
  dense?: boolean
  hover?: boolean
  strip?: boolean
}

interface TableContext {
  dense: boolean
  hover: boolean
  strip: boolean
}

const Context = React.createContext<TableContext>(undefined!)

export function useTableContext() {
  return useContext(Context)
}

export const Table: React.FC<TableProps> = ({ className, children, ...props }) => {
  const context = {
    dense: Boolean(props.dense),
    hover: Boolean(props.hover),
    strip: Boolean(props.strip)
  }
  return (
    <Context.Provider value={context}>
      <div className={className}>{children}</div>
    </Context.Provider>
  )
}
