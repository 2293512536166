import React, { useState } from 'react'

import { Icon } from 'shared/ui/icon'
import { Button } from 'shared/ui/button/Button'

import classes from './Recover.module.scss'
import { toFormData } from 'shared/util/form-data'
import { useHistory } from 'react-router-dom'

export const Recover: React.FC = () => {
  const history = useHistory()
  const [email, setEmail] = useState<string>()

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    const init: RequestInit = {
      method: 'POST',
      body: toFormData({ email })
    }

    fetch('/api/auth/new-password', init).then(() => history.push('/recover/done'))
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.logo}>
          <img src="/img/logo-birdie.svg" alt="Logo Birdie" />
        </div>
        <form className={classes.form} onSubmit={onSubmit}>
          <div className={classes.content}>
            <h1 className={classes.title}>Recuperar conta</h1>
            <div className={classes.input}>
              <span className={classes.icon}>
                <Icon.Envelope />
              </span>
              <input
                type="email"
                placeholder="E-mail"
                autoComplete="email"
                onChange={event => setEmail(event.target.value)}
                value={email}
                required
              />
            </div>
            <div className={classes.action}>
              <Button className={classes.button}>SEGUINTE</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
