import { useEffect, useRef, useState } from 'react'

export function useRefState<T>(value: T) {
  const ref = useRef(value)
  const [current, setCurrent] = useState(value)
  useEffect(() => {
    if (ref.current !== value) {
      setCurrent((ref.current = value))
    }
  }, [value])
  return current
}
