import React, { useState } from 'react'

import { SolarOrientation, getSolarOrienationDescription } from 'shared/enum/util/SolarOrientation'
import { FormGroup } from 'shared/form/FormGroup'
import { InputGroup } from 'shared/form/InputGroup'
import { InputGroupText } from 'shared/form/InputGroupText'
import { Input } from 'shared/form/Input'
import { InputSelect } from 'shared/form/InputSelect'
import { InputSelectItem } from 'shared/form/InputSelectItem'
import { Button, RoundButton } from 'shared/ui/button'
import { Icon } from 'shared/ui/icon'
import { toFormData } from 'shared/util/form-data'

import classes from './SessionDeliverableCaptureTour.module.scss'

interface Props {
  product: Resource.SessionResourceProduct<Resource.SessionDeliverableUrl>
  category?: 'admin' | 'client' | 'provider'
  session: Resource.SessionResource
}

interface Deliverable {
  id?: number
  url?: string
  data: {
    orientation?: string
  }
}

const defaultTour = () => ({
  url: '',
  data: {
    orientation: ''
  }
})

export const SessionDeliverableCaptureTour: React.FC<Props> = ({ product, session }) => {
  const [tours, setTours] = useState<Deliverable[]>(() => {
    if (product.deliverables.length) {
      return product.deliverables.map(({ id, url, data }) => ({
        id: id,
        url: url || '',
        data: {
          orientation: data?.orientation || ''
        }
      }))
    } else {
      return [defaultTour()]
    }
  })
  const handleAdd = (event: React.MouseEvent) => {
    event.preventDefault()
    setTours(tours => [...tours, defaultTour()])
  }
  const handleChange = (i: number) => (key: keyof Deliverable) => (value: string) => {
    setTours(tours => [...tours.slice(0, i), { ...tours[i], [key]: value }, ...tours.slice(i + 1)])
  }
  const handleDataChange = (i: number) => (key: keyof Deliverable['data']) => (value: string | number) => {
    const data = Object.assign(tours[i].data, { [key]: value })
    setTours(tours => [...tours.slice(0, i), { ...tours[i], data: { ...data } }, ...tours.slice(i + 1)])
  }
  const handleDelete = (i: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    const tour = tours[i]
    if (tour.id) {
      const url = `/api/sessions/${session.id}/${product.id}/urls/${tour.id}`
      fetch(url, { method: 'DELETE' })
        .then(response => {
          if (response.ok) {
            const index = tours.findIndex(({ id }) => id === tour.id)
            setTours(tours => [...tours.slice(0, index), ...tours.slice(index + 1)])
          } else {
            throw new Error()
          }
        })
        .catch(() => alert('Falha ao remover link de tour!'))
    } else {
      setTours(tours => [...tours.slice(0, i), ...tours.slice(i + 1)])
    }
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const baseUrl = `/api/sessions/${session.id}/${product.id}/urls`
    const requests = tours.map(({ id, ...tour }, i) => {
      const url = baseUrl + ((id && `/${id}`) || '')
      const body = toFormData({ ...tour, order: i })
      return fetch(url, { body, method: 'POST' }).then(async response => {
        if (response.ok) {
          const { data } = await response.json()
          setTours(tours => [...tours.slice(0, i), { ...tours[i], id: data.id }, ...tours.slice(i + 1)])
        } else {
          throw new Error()
        }
      })
    })
    Promise.all(requests)
      .then(() => alert('Salvo com sucesso!'))
      .catch(() => alert('Falha ao salvar a URL!'))
  }
  return (
    <div className={classes.content}>
      <div className={classes.deliverables}>
        <form onSubmit={handleSubmit}>
          {tours.map((tour, i) => (
            <div className={classes.tour} key={i}>
              <FormGroup className={classes.link} label="Link do Tour 3D">
                <InputGroup>
                  <InputGroupText>
                    <Icon.Link />
                  </InputGroupText>
                  <Input type="url" onChange={handleChange(i)('url')} value={tour.url} />
                </InputGroup>
              </FormGroup>
              <FormGroup className={classes.orientation} label="Orientação Solar">
                <InputSelect onChange={handleDataChange(i)('orientation')} value={tour.data?.orientation}>
                  {Object.values(SolarOrientation).map(orientation => (
                    <InputSelectItem key={orientation} value={orientation}>
                      {`${orientation} - ${getSolarOrienationDescription(orientation)}`}
                    </InputSelectItem>
                  ))}
                </InputSelect>
              </FormGroup>
              <RoundButton className={classes.delete} onClick={handleDelete(i)}>
                <Icon.Times />
              </RoundButton>
            </div>
          ))}
          <div className={classes.buttons}>
            <Button onClick={handleAdd} outline size="sm" variant="info">
              ADICIONAR TOUR
            </Button>
            <Button size="sm" type="submit">
              SALVAR
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
