import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { addDays, addSeconds, format, isPast, parseISO, subDays } from 'date-fns'

import { ItemWorkType } from 'shared/enum/item'
import { SessionStatus } from 'shared/enum/session/SessionStatus'
import { useSessions } from 'shared/hooks/data/session'
import { useModal } from 'shared/modal'
import { Button } from 'shared/ui/button/Button'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'
import { Icon } from 'shared/ui/icon'
import { classNames } from 'shared/util/class-names'
import { parseAddress } from 'shared/util/google-maps'

import { useAuth } from 'components/auth/AuthContext'

import { SessionReportModal } from '../sessions/session-view/components/SessionReportModal'

import { BottomNavigation } from './components/BottomNavigation'
import { SessionsMap } from './components/SessionsMap'

import classes from './Dashboard.module.scss'

const today = addDays(new Date(), 0)
const todayDate = () => format(today, 'yyyy-MM-dd')
const tomorrowDate = () => format(addDays(today, 1), 'yyyy-MM-dd')
const yesterdayDate = () => format(subDays(today, 2), 'yyyy-MM-dd')

export const Dashboard: React.FC = () => {
  const [mapOpened, setMapOpened] = useState(false)
  const sessionReportModal = useModal(SessionReportModal)
  const handleReport = (id: number, cancel: boolean) => () => sessionReportModal.open({ cancel, session: { id } })
  const auth = useAuth()!
  const deliver = useRef({
    filter: {
      scheduled_for: ['', yesterdayDate()],
      status: SessionStatus.SCHEDULED
    },
    order: ['scheduled_for']
  })
  const today = useRef({
    filter: { scheduled_for: [todayDate(), todayDate()] },
    order: ['scheduled_for']
  })
  const tomorrow = useRef({
    filter: { scheduled_for: [tomorrowDate(), tomorrowDate()] },
    order: ['scheduled_for']
  })
  const sessionsToday = useSessions(today.current)
  const sessionsTomorrow = useSessions(tomorrow.current)
  const sessionsDeliver = useSessions(deliver.current)
  const handleMapOpen = () => setMapOpened(mapOpened => !mapOpened)
  return (
    <Container className={classes.dashboard}>
      <p className={classes.name}>Olá {auth.name}!</p>
      <div className={classes.titleContainer}>
        <p className={classes.title}>Seus ensaios de hoje :)</p>
        <button className={classes.toggleMap} onClick={handleMapOpen} type="button">
          <Card className={classes.card} shadow>
            <Icon.MapMarkedAlt className={classes.icon} size="lg" />
            <span>Ver no mapa</span>
          </Card>
        </button>
      </div>
      <div className={classes.sessions}>
        {sessionsToday?.map(session => {
          const address = parseAddress(session.address?.address_components)
          const items = (session.service.products || [])
            .flatMap(product => product.item?.items)
            .filter(item => item && item.work_type === ItemWorkType.LOCAL)
          const scheduledFor = parseISO(session.scheduled_for!)
          return (
            <Card key={session.id} className={classNames(classes.card, classes.black)} variant="black">
              <div className={classes.heading}>
                <p className={classes.scheduled_for}>
                  {format(scheduledFor, 'HH:mm')}
                </p>
                <Link className={classes.detail} to={`/sessions/${session.id}`}>
                  Ver detalhes <Icon.ArrowRight />
                </Link>
              </div>
              <div className={classes.address}>
                <p>
                  {address.get('neighborhood')}, {address.get('city')}
                </p>
                <p>
                  {address.get('street')}
                  {address.has('number') && `, ${address.get('number')}`}
                  {session.vertical_data?.unit && ` - ${session.vertical_data?.unit}`}
                </p>
              </div>
              <p className={classes.responsible}>
                {session.responsible_information.name}
                {' • '}
                {session.client.name}
              </p>
              {items.map(
                item =>
                  item && (
                    <div className={classes.product} key={item.id}>
                      <span>{item.title}</span>
                      <ReactSVG className={classes.icon} src={item.icon} />
                    </div>
                  )
              )}
              {session.status === SessionStatus.SCHEDULED && (
                <div className={classes.actions}>
                  <Button outline size="sm" onClick={handleReport(session.id, false)} type="button">
                    <Icon.Check /> CHECK-IN
                  </Button>
                  <Button outline size="sm" onClick={handleReport(session.id, true)} type="button">
                    <Icon.Times /> CANCELAR
                  </Button>
                </div>
              )}
            </Card>
          )
        })}
      </div>
      {sessionsToday?.length === 0 && <p>Nenhum ensaio agendado para hoje</p>}
      <hr />
      <p className={classes.title}>Ensaios para amanhã</p>
      <div className={classNames(classes.sessions, classes.tomorrow)}>
        {sessionsTomorrow?.map(session => {
          const address = parseAddress(session.address?.address_components)
          const items = (session.service.products || [])
            .flatMap(product => product.item?.items)
            .filter(item => item && item.work_type === ItemWorkType.LOCAL)
          const scheduledFor = parseISO(session.scheduled_for!)
          return (
            <Card key={session.id} className={classes.card} shadow>
              <div className={classes.heading}>
                <p className={classes.scheduled_for}>
                  {format(scheduledFor, 'HH:mm')}
                  {' - '}
                  {format(addSeconds(scheduledFor, session.duration), 'HH:mm')}
                </p>
                <Link className={classes.detail} to={`/sessions/${session.id}`}>
                  Ver detalhes <Icon.ArrowRight />
                </Link>
              </div>
              <div className={classes.address}>
                <p>
                  {address.get('neighborhood')}, {address.get('city')}
                </p>
              </div>
              {items.map(
                item =>
                  item && (
                    <div className={classes.product} key={item.id}>
                      <span>{item.title}</span>
                      <ReactSVG className={classes.icon} src={item.icon} />
                    </div>
                  )
              )}
            </Card>
          )
        })}
      </div>
      {sessionsTomorrow?.length === 0 && <p>Nenhum ensaio agendado para amanhã</p>}
      <hr />
      <p className={classes.title}>Ensaios para entregar</p>
      <div className={classes.sessions}>
        {sessionsDeliver?.map(session => {
          const address = parseAddress(session.address?.address_components)
          const scheduledFor = parseISO(session.scheduled_for!)
          return (
            <Card key={session.id} className={classes.card} shadow>
              <div className={classes.heading}>
                <p className={classes.scheduled_for}>
                  {format(scheduledFor, 'HH:mm')}
                  {' - '}
                  {format(addSeconds(scheduledFor, session.duration), 'HH:mm')}
                </p>
                <Link className={classes.detail} to={`/sessions/${session.id}`}>
                  Ver detalhes <Icon.ArrowRight />
                </Link>
              </div>
              <div className={classes.address}>
                <p>
                  {address.get('neighborhood')}, {address.get('city')}
                </p>
                <p>
                  {address.get('street')}, {address.get('number')}
                </p>
              </div>
              {isPast(scheduledFor) && (
                /*session.status === SessionStatus.SCHEDULED && */ <div className={classes.actions}>
                  <Button outline size="sm" onClick={handleReport(session.id, false)} type="button">
                    <Icon.Check /> REALIZADO
                  </Button>
                  <Button outline size="sm" onClick={handleReport(session.id, true)} type="button">
                    <Icon.Times /> CANCELADO
                  </Button>
                </div>
              )}
            </Card>
          )
        })}
      </div>
      {sessionsDeliver?.length === 0 && <p>Nenhum ensaio para ser entregue</p>}
      <div className={classNames(classes.map, mapOpened && classes.opened)}>
        <SessionsMap className={classes.view} sessions={sessionsToday} />
        <Button className={classes.close} onClick={handleMapOpen} type="button">
          <Icon.List className={classes.icon} /> Voltar à lista
        </Button>
      </div>
      {((sessionsToday?.length || 0) > 0) && <BottomNavigation sessions={sessionsToday} />}
    </Container>
  )
}
