import { useEffect, useState } from 'react'

import { toFormData } from '../../util/form-data'

export function useInvoices(params?: Http.Params, paging?: Http.Pagination, setMeta?: (_: Http.ResponseMeta) => void) {
  const [invoices, setInvoices] = useState<Model.Invoice[]>()
  useEffect(() => {
    setInvoices(undefined)
    const request = {
      body: toFormData(params),
      method: 'POST'
    }
    const search = new URLSearchParams()
    if (paging) {
      search.set('page', `${paging.page}`)
      search.set('length', `${paging.length}`)
    }
    fetch(`/api/invoices/search?${search.toString()}`, request)
      .then(response => response.json())
      .then(({ data, meta }) => {
        setInvoices(data)
        setMeta?.(meta)
      })
  }, [paging, params, setMeta])
  return invoices
}
