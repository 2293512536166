import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { SessionList } from './session-list/SessionList'
import { SessionView } from './session-view/SessionView'

export const Sessions: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}`} exact component={SessionList} />
    <Route path={`${match.path}/:id`} component={SessionView} />
  </Switch>
)
