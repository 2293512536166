import React from 'react'

import { classNames } from 'shared/util/class-names'

import { useTableContext } from './Table'

import classes from './TableColumn.module.scss'

interface TableColumnProps {
  align?: 'center' | 'flex-start' | 'flex-end'
  className?: string
  dir?: 'column' | 'row'
  justify?: 'space-around'
  name?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  size?: number
  verticalAlign?: 'center' | 'flex-start' | 'flex-end'
}

export const TableColumn: React.FC<TableColumnProps> = ({
  align,
  className,
  dir,
  justify,
  size,
  verticalAlign,
  ...props
}) => {
  const context = useTableContext()
  const params = {
    className: classNames(
      classes.column,
      context.dense && classes.dense,
      props.onClick && classes.clickable,
      className
    ),
    style: {
      alignItems: align,
      flexBasis: 0,
      flexDirection: dir,
      flexGrow: size || 1,
      flexShrink: 0,
      justifyContent: verticalAlign || justify || 'center',
      textAlign: align === 'center' ? ('center' as 'center') : ('initial' as 'initial')
    }
  }
  return <div {...props} {...params} />
}
