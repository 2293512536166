import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { addSeconds, isPast, parseISO, subHours } from 'date-fns'

import { Card, CardBody } from 'shared/bootstrap/card'
import { SessionDeliverables } from 'shared/components/deliverables/SessionDeliverables'
import { ItemWorkType } from 'shared/enum/item'
import { SessionStatus, getSessionStatusColor, getSessionStatusDescription } from 'shared/enum/session/SessionStatus'
import { useModal } from 'shared/modal'
import { Badge } from 'shared/ui/badge/Badge'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Button } from 'shared/ui/button/Button'
import { Container } from 'shared/ui/container/Container'
import { Icon } from 'shared/ui/icon'
import { classNames } from 'shared/util/class-names'
import { formatDate, formatISO } from 'shared/util/date'
import { parseAddress, toGoogleMapsUrl } from 'shared/util/google-maps'
import { toAsset } from 'shared/util/url'

import { useAuth } from 'components/auth/AuthContext'
import { useSession } from 'hooks/data/session'

import { SessionReport } from './components/SessionReport'
import { SessionReportModal } from './components/SessionReportModal'
import { SessionScheduled } from './components/SessionScheduled'

import classes from './SessionView.module.scss'

export const SessionView: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const auth = useAuth()
  const hasWorkTypeLocal = auth?.provider?.items.find(item => {
    return item.work_type === ItemWorkType.LOCAL && item.deliverable
  })
  const session = useSession(match.params)
  const sessionReportModal = useModal(SessionReportModal)
  const handleReport = (cancel: boolean) => (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    sessionReportModal.open({ cancel, session: session! })
  }
  if (session) {
    const address = parseAddress(session.address?.address_components)
    const mapUrl = toGoogleMapsUrl(session.address?.geometry?.location)
    const scheduledFor = parseISO(session.scheduled_for!)
    return (
      <Container>
        <Breadcrumb>
          <BreadcrumbItem>Ensaios</BreadcrumbItem>
          <BreadcrumbItem>Ensaio {session.id}</BreadcrumbItem>
        </Breadcrumb>
        <main className={classNames(classes.main)}>
          <div className={classes.info}>
            <Card shadow>
              <CardBody>
                <div className={classes.session}>
                  <div className={classes.image}>
                    {session.client.logo_url ? <img src={toAsset(session.client.logo_url)} alt="Logo" /> : 'Sem logo'}
                  </div>
                  <h1 className={classes.client}>{session.client.name}</h1>
                  <p className={classes.id}>ID {session.id}</p>
                  <Badge color={getSessionStatusColor(session.status)}>
                    {getSessionStatusDescription(session.status)}
                  </Badge>
                </div>
                <hr />
                <div className={classes.scheduled_for}>
                  <p className={classes.day}>{formatISO(session.scheduled_for!, 'EEEE dd/MM/yy')}</p>
                  <p className={classes.hour}>
                    {formatISO(session.scheduled_for!, 'HH:mm')}
                  </p>
                </div>
                {hasWorkTypeLocal && (
                  <div className={classes.address}>
                    <div>
                      {address.get('street')}
                      {address.has('number') && `, ${address.get('number')}`}
                      {session.vertical_data?.unit && ` - ${session.vertical_data?.unit}`}
                    </div>
                    {session.vertical_data?.complement && <div>{session.vertical_data?.complement}</div>}
                    <div>
                      {address.get('neighborhood')}, {address.get('city')}/{address.get('state')}
                    </div>
                    {!isPast(subHours(scheduledFor, 1)) && (
                      <Button variant="link" href={mapUrl}>
                        Abrir no mapa <Icon.ArrowRight />
                      </Button>
                    )}
                    {session.vertical_data?.area !== undefined && (
                      <div className={classes.area}>
                        <span>Área informada: </span>
                        <strong>{session.vertical_data.area.toLocaleString('pt-BR')}m²</strong>
                      </div>
                    )}
                  </div>
                )}
                {session.status === SessionStatus.SCHEDULED && hasWorkTypeLocal && (
                  <div className={classes.actions}>
                    <Button className={classes.button} outline onClick={handleReport(false)} type="button">
                      <Icon.Check className={classes.check} />
                      <span>INICIAR ATENDIMENTO</span>
                    </Button>
                    <Button className={classes.button} outline size="sm" variant="danger" onClick={handleReport(true)} type="button">
                      <Icon.Times className={classes.cancel} />
                      <span>CANCELAR</span>
                    </Button>
                  </div>
                )}
              </CardBody>
            </Card>
            {session.report && <SessionReport report={session.report} />}
          </div>
          <div className={classes.wrapper}>
            {session.status !== SessionStatus.DELIVERED &&
              session.status !== SessionStatus.CANCELED_NOT_CHARGED &&
              session.status !== SessionStatus.CANCELED_CHARGED &&
              session.status !== SessionStatus.CANCELED_DISPLACEMENT &&
              hasWorkTypeLocal && <SessionScheduled session={session} />}
            {/** Uncomment when scheduling for any date is available on admin */}
            {/* {isValid(scheduledFor) && isPast(scheduledFor) && ( */}
            <SessionDeliverables session={session} category="provider" />
            {/* )} */}
          </div>
        </main>
      </Container>
    )
  } else {
    return null
  }
}
