import React, { useRef, useState } from 'react'

import { classNames } from '../util/class-names'

import classes from './FormUpload.module.scss'

interface FormUploadProps {
  accept?: string
  disabled?: boolean
  multiple?: boolean
  onChange?: (_: File[]) => void
}

export const FormUpload: React.FC<FormUploadProps> = ({ onChange, children, ...props }) => {
  const input = useRef<HTMLInputElement>(null)
  const [dragging, setDragging] = useState(false)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files)
      if (files.length) {
        onChange?.(files)
      }
    }
  }
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    input.current?.click()
  }
  const handleDragOut = (event: React.DragEvent) => {
    event.preventDefault()
    setDragging(false)
  }
  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault()
    setDragging(true)
  }
  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault()
    setDragging(false)
    const items = Array.from(event.dataTransfer.items)
      .map(item => item.getAsFile())
      .filter(Boolean)
    if (items.length) {
      onChange?.(items as File[])
    }
  }
  const wrapper = {
    className: classNames(classes.wrapper, dragging && classes.dragging),
    onClick: handleClick,
    onDragEnd: handleDragOut,
    onDragLeave: handleDragOut,
    onDragOver: handleDragOver,
    onDrop: handleDrop
  }
  return (
    <div {...wrapper}>
      <input type="file" onChange={handleChange} ref={input} {...props} />
      <div className={classes.content} children={children} />
      <div className={classes.drag}>
        <span>Solte os arquivos para fazer o upload</span>
      </div>
    </div>
  )
}
