export enum Item {
  CAPTURE_TOUR = 5,
  EDIT_TOUR = 6,
  PRESENTATION_TOUR = 7,
  CAPTURE_PHOTOS = 8,
  EDIT_PHOTOS = 9,
  VIDEOS = 10,
  BLUEPRINTS = 11,
  CAPTURE_TOUR_PHOTOS_TOUR = 13,
  CAPTURE_TOUR_PHOTOS_PHOTOS = 14,
  EDIT_TOUR_PHOTOS = 15,
  PRESENTATION_TOUR_PHOTOS = 16,
  COPYWRITING = 18,
  CAPTURE_BLUEPRINT = 19,
  CAPTURE_TOUR_INSPECT = 21,
  VIDEOS_PREMIUM = 23,
  PRESENTATION_TOUR_INSPECT = 26,
  CAPTURE_PHOTOS_PREMIUM = 44
}

// Menu entregáveis
// - Vir preenchida a data atual no campo "data fim"
// - no lugar do Aguardando material indicar os seguinte status:
//           "Planta Em Processamento" ou "Planta Pendente de Envio" ou "Planta Pronta para Revisão" e "Planta Finalizada"
// - Alterar o serviço dentro do Admin
