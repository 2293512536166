import React from 'react'

import { Badge } from 'shared/ui/badge/Badge'
import { getSessionStatusColor, getSessionStatusDescription, SessionStatus } from 'shared/enum/session/SessionStatus'

interface StatusProps {
  status: SessionStatus
}

export const SessionStatusLabel = React.memo<StatusProps>(({ status }) => (
  <Badge color={getSessionStatusColor(status)} size="sm">
    {getSessionStatusDescription(status)}
  </Badge>
))
