import React, { useState } from 'react'
import { addMonths, endOfMonth, isThisMonth, startOfMonth, subMonths } from 'date-fns'

import { Card, CardBody } from 'shared/bootstrap/card'
import { SessionStatusLabel } from 'shared/components/sessions/SessionStatusLabel'
import { useSessions } from 'shared/hooks/data/session'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon'
import { Table, TableBody, TableColumn, TableHeader, TablePaginator, TableRow } from 'shared/ui/table'
import { formatDecimal } from 'shared/util'
import { formatDate, formatISO } from 'shared/util/date'
import { parseAddress } from 'shared/util/google-maps'
import { useSkipEffect } from 'shared/util/hooks'

import { useAuth } from 'components/auth/AuthContext'

import classes from './FinantialSessionMonth.module.scss'

interface ResponseMeta extends Http.ResponseMeta {
  provider: {
    cost: number
  }
}

function formatParams(date: Date) {
  const start = startOfMonth(date)
  const end = endOfMonth(date)
  return {
    filter: {
      scheduled_for: [formatDate(start), formatDate(end)]
    },
    order: ['scheduled_for'],
    with: { provider: 'cost' }
  }
}

export const FinantialSessionMonth: React.FC = () => {
  const [date, setDate] = useState(subMonths(new Date(), 1))
  const [meta, setMeta] = useState<ResponseMeta>()
  const [paging, setPaging] = useState<Http.Pagination>({ page: 1, length: 50 })
  const [params, setParams] = useState(formatParams(date))
  const auth = useAuth()
  const sessions = useSessions(params, paging, setMeta)
  useSkipEffect(1, () => setParams(formatParams(date)), [date])
  return (
    <Card>
      <CardBody>
        <div className={classes.header}>
          <nav className={classes.nav}>
            <button type="button" onClick={() => setDate(date => subMonths(date, 1))}>
              <Icon.AngleLeft />
            </button>
            <span className={classes.date}>{formatDate(date, "MMMM 'de' yyyy")}</span>
            <button
              type="button"
              disabled={isThisMonth(addMonths(date, 2))}
              onClick={() => setDate(date => addMonths(date, 1))}
            >
              <Icon.AngleRight />
            </button>
          </nav>
          <div>
            <strong>
              {meta?.provider.cost &&
                `R$ ${meta.provider.cost.toLocaleString('pt-BR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}`}
            </strong>
          </div>
        </div>
        <Table dense hover strip>
          <TableHeader>
            <TableColumn align="center">ID</TableColumn>
            <TableColumn align="center" size={2}>
              Cliente
            </TableColumn>
            <TableColumn align="center" size={2}>
              Ensaio
            </TableColumn>
            <TableColumn align="center">Valor</TableColumn>
            <TableColumn align="center">Área</TableColumn>
            <TableColumn align="center" size={2}>
              Serviço
            </TableColumn>
            <TableColumn align="center">Status</TableColumn>
            {/*<TableColumn align="center">SLA</TableColumn>*/}
          </TableHeader>
          <TableBody>
            {sessions?.map(session => {
              const address = parseAddress(session.address?.address_components)
              const area = formatDecimal(session.vertical_data.area)
              const products = Array.from(session.products || []).filter(product => {
                return product.item.work_type === 2 && product.provider?.id === auth?.provider.id
              })
              const cost = products.reduce((total, product) => total + (product.cost || 0), 0)
              return (
                <TableRow key={session.id}>
                  <TableColumn align="center">
                    <Button className={classes.id} size="sm" to={`/sessions/${session.id}`}>
                      <span>{session.id}</span>
                      <Icon.AngleRight />
                    </Button>
                  </TableColumn>
                  <TableColumn align="center" size={2}>
                    <div>{session.client.name}</div>
                  </TableColumn>
                  <TableColumn align="center" size={2}>
                    <div>
                      {address.get('street')}
                      {address.has('number') && `, ${address.get('number')}`}
                      {session.vertical_data?.unit && ` - ${session.vertical_data?.unit}`}
                    </div>
                    <div>{formatISO(session.scheduled_for!, 'dd/MM/yyyy HH:mm')}</div>
                  </TableColumn>
                  <TableColumn align="center">
                    R$ {formatDecimal(cost || 0)}
                  </TableColumn>
                  <TableColumn align="center">{area || '-'} m²</TableColumn>
                  <TableColumn align="center" size={2}>
                    {products.map(product => (
                      <div key={product.id}>{product.item.label}</div>
                    ))}
                  </TableColumn>
                  <TableColumn align="center">
                    <SessionStatusLabel status={session.status} />
                  </TableColumn>
                </TableRow>
              )
            })}
          </TableBody>
          <TablePaginator meta={meta} onChange={(page, length) => setPaging({ page, length })} />
        </Table>
      </CardBody>
    </Card>
  )
}
