import React, { createContext, useContext, useEffect, useRef, useState } from 'react'

import { DropdownMenu } from 'shared/bootstrap/dropdown/DropdownMenu'
import { Icon } from 'shared/ui/icon'
import { classNames } from 'shared/util/class-names'

import { useFormGroupContext } from './FormGroupNew'

import classes from './InputSelect.module.scss'

interface InputSelectProps {
  onChange?: (_: number | string) => void
  placeholder?: string
  renderItem?: <T>(_: T) => React.ReactElement
  value?: number | string
}

interface InputSelectContextProps {
  items: Map<number | string, string>
  onClick: (_: [number | string, string]) => void
}

const clickEvent = new Set<(event: MouseEvent) => void>()
const escEvent = new Set<() => void>()

window.addEventListener('click', event => {
  clickEvent.forEach(callback => callback(event))
})

window.addEventListener('keyup', event => {
  if (event.key === 'Escape') {
    escEvent.forEach(callback => callback())
  }
})

const InputSelectContext = createContext<InputSelectContextProps>(undefined!)

export function useInputSelectContext() {
  return useContext(InputSelectContext)!
}

export const InputSelect: React.FC<InputSelectProps> = ({ children, onChange, renderItem, ...props }) => {
  const [opened, setOpened] = useState(false)
  const [selected, setSelected] = useState('')
  const [value, setValue] = useState('')
  const formGroup = useFormGroupContext()
  const context = useRef<InputSelectContextProps>({
    items: new Map(),
    onClick: ([key, value]) => {
      onChange?.(key)
      setOpened(false)
      setSelected(value)
      setValue(value)
    }
  })
  const params = {
    className: classNames('form-control', classes.input),
    id: formGroup?.id,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelected('')
      setValue(event.target.value)
    },
    onFocus: () => setOpened(true),
    value: selected || value || props.value || ''
  }
  const handleBackdrop = () => setOpened(false)
  const handleClear = () => {
    onChange?.('')
    setOpened(false)
    setSelected('')
    setValue('')
  }
  useEffect(() => {
    const handleEscape = () => setOpened(false)
    escEvent.add(handleEscape)
    return () => {
      escEvent.delete(handleEscape)
    }
  }, [])
  useEffect(() => {
    if (props.value) {
      const text = context.current.items.get(props.value)
      if (text) {
        setValue(text)
      }
    }
  }, [props.value])
  return (
    <InputSelectContext.Provider value={context.current}>
      <div className={classes.select}>
        <div className={classNames(classes.backdrop, opened && classes.visible)} onClick={handleBackdrop} />
        <div className={classes.input}>
          <input readOnly {...props} {...params} />
          <button
            className={classNames(classes.clear, (Boolean(value) || Boolean(props.value)) && classes.visible)}
            onClick={handleClear}
            type="button">
            <Icon.Times />
          </button>
        </div>
        <DropdownMenu className={classes.menu} opened={opened}>
          {children}
        </DropdownMenu>
      </div>
    </InputSelectContext.Provider>
  )
}
