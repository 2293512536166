import React from 'react'
import { NavLink } from 'react-router-dom'

import { classNames } from '../../util/class-names'

interface Props {
  activeClassName?: string
  className?: string
  disabled?: boolean
  exact?: boolean
  outline?: boolean
  size?: 'lg' | 'sm'
  target?: string
  to: string
  variant?: 'danger' | 'dark' | 'info' | 'light' | 'link' | 'primary' | 'secondary' | 'success' | 'warning'
}

export const NavButton: React.FC<Props> = ({ className, outline, size, variant, ...props }) => {
  const klass = classNames(
    'btn',
    `btn${outline ? '-outline' : ''}-${variant || 'primary'}`,
    size && `btn-${size}`,
    className
  )
  return <NavLink className={klass} {...props} />
}
