import React from 'react'

import { classNames } from '../../util/class-names'

interface Props {
  className?: string
}

export const ModalBody: React.FC<Props> = ({ className, ...props }) => {
  return <div className={classNames('modal-body', className)} {...props} />
}
