import React, { useState } from 'react'

import { FormState } from 'shared/form/enum/FormState'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon/Icon'
import { toFormData } from 'shared/util/form-data'

import { useAuth } from 'components/auth/AuthContext'

// import classes from './SessionListColumnAccept.module.scss'

interface SessionListColumnAcceptProps {
  onChange?: () => void
  session: Model.Session
}

export const SessionListColumnAccept: React.FC<SessionListColumnAcceptProps> = ({ onChange, session }) => {
  const auth = useAuth()
  const products = session.products?.filter(({ item }) => {
    return auth?.provider?.items.find(({ deliverable, id }) => id === item.id && deliverable)
  })
  const [state, setState] = useState(FormState.INITIAL)
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    setState(state => {
      if (state === FormState.INITIAL) {
        const body = toFormData({
          provider_id: auth?.provider.id
        })
        const requests = products?.map(({ id }) => {
          return fetch(`/api/sessions/${session.id}/${id}`, { body, method: 'POST' }).catch(() =>
            alert('Falha ao aceitar ensaio')
          )
        })
        if (requests) {
          Promise.allSettled(requests).then(() => onChange?.())
          return FormState.SUBMITTING
        }
      }
      return state
    })
  }
  if (products?.filter(({ provider }) => provider?.id !== null).length) {
    return null
  }
  return (
    <div>
      {state === FormState.INITIAL && (
        <Button onClick={handleClick} size="sm">
          ACEITAR
        </Button>
      )}
      {state === FormState.SUBMITTING && <Icon.CircleNotch size="2x" spin />}
    </div>
  )
}
