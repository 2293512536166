import React, { useState } from 'react'

import { useSkipEffect } from 'shared/util/hooks'

import { useDebouncedCallback } from '../util'

import { BaseDataSource, DataSourceItem } from './BaseDataSource'

interface Props {
  itemId?: number
}

export const ProviderDataSource: React.FC<Props> = ({ children, itemId }) => {
  const [items, setItems] = useState<DataSourceItem[]>()
  const [previous, setPrevious] = useState<string | null>(null)
  // const [query, setQuery] = useState('') ??
  const handleChange = useDebouncedCallback(
    (value: string) => {
      if (previous !== value) {
        setPrevious(value)
        const abort = new AbortController()
        const params = new URLSearchParams({
          length: '100',
          search: value,
          v: '2'
        })
        if (itemId) {
          params.set('item[id]', `${itemId}`)
        }
        fetch(`/api/providers?${params.toString()}`, { signal: abort.signal })
          .then(response => response.json())
          .then(response => response.data as Model.Provider[])
          .then(items => items.map(({ id, name }) => [id, name] as DataSourceItem))
          .then(items => setItems(items))
          .catch(error => {
            if (error instanceof DOMException && error.code === DOMException.ABORT_ERR) {
              return null
            }
            throw new Error(error)
          })
        return () => abort.abort()
      }
    },
    [],
    300
  )
  useSkipEffect(0, () => handleChange(''), [handleChange])
  return (
    <BaseDataSource items={items} onChange={handleChange}>
      {children}
    </BaseDataSource>
  )
}
