import React from 'react'

import { classNames } from '../../util/class-names'

import classes from './RoundButton.module.scss'

interface RoundButtonProps {
  active?: boolean
  className?: string
  onClick?: (_: React.MouseEvent) => void
  size?: 'sm' | 'md'
  type?: 'button' | 'reset' | 'submit'
  variant?: 'default' | 'primary' | 'text'
}

export const RoundButton: React.FC<RoundButtonProps> = ({ active, className, size, variant, ...props }) => {
  size = size || 'md'
  variant = variant || 'default'
  const klass = classNames(
    classes.button,
    active && classes.active,
    size && classes[`size-${size}`],
    variant && classes[`variant-${variant}`],
    className
  )
  return <button className={klass} {...props} />
}
