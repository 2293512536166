import React from 'react'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'

import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'

import { Password } from './components/Password'

import classes from './profile.module.scss'

export const Profile: React.FC = () => {
  return (
    <Container className={classes.wrapper}>
      <Container className={classes.navigator}>
        <Card>
          <NavLink to="/profile/password">Senha</NavLink>
        </Card>
      </Container>
      <Container className={classes.content}>
        <Switch>
          <Route path="/profile/password" component={Password} />
          <Redirect to="/profile/password" />
        </Switch>
      </Container>
    </Container>
  )
}
