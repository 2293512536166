import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ItemWorkType } from 'shared/enum/item'

type AuthContextType = ReactState<Optional<Model.ProviderUser>>

const AuthContext = React.createContext<Nullable<AuthContextType>>(null)

export function useAuth() {
  const [auth] = useContext(AuthContext)!
  if (auth) {
    const hasWorkType = (workType: ItemWorkType) =>
      auth?.provider.items.findIndex(item => {
        return item.work_type === workType && item.deliverable
      })
    return {
      ...auth,
      hasWorkTypeLocal: hasWorkType(ItemWorkType.LOCAL) >= 0,
      hasWorkTypeRemote: hasWorkType(ItemWorkType.REMOTE) >= 0
    }
  } else if (auth === null) {
    return null
  }
}

export function useAuthContext() {
  return useContext(AuthContext)!
}

export const AuthContextProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const [user, setUser] = useState<Optional<Model.ProviderUser>>()

  useEffect(() => {
    fetch(`/api/auth/user`)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error()
        }
      })
      .then(({ data }) => {
        if (data.scope === 'provider') {
          setUser(data)
          if (data.NEED_TO_CHANGE_PASSWORD) {
            history.push('/profile/password')
          }
        } else {
          throw new Error()
        }
      })
      .catch(() => setUser(null))
  }, [history])
  return <AuthContext.Provider value={[user, setUser]}>{children}</AuthContext.Provider>
}
