import React, { useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { Button } from 'shared/ui/button/Button'

import { Icon } from 'shared/ui/icon'
import { classNames } from 'shared/util/class-names'

import { useAuth, useAuthContext } from './auth/AuthContext'

import classes from './Navbar.module.scss'

export const Navbar: React.FC = () => {
  const history = useHistory()
  const [, setAuth] = useAuthContext()
  const user = useAuth()!
  const [opened, setOpened] = useState(false)

  const handleClick = (event: React.MouseEvent) => {
    if (event.target instanceof HTMLElement) {
      if (event.target.classList.contains(classes.link)) {
        setOpened(false)
      }
    }
  }

  const logOut = () => {
    fetch('/api/auth/logout')
      .then(() => setAuth(null))
      .then(() => history.replace('/login'))
  }

  return (
    <header className={classes.header}>
      <div className={classes.content}>
        <Link to="/">
          <img src="/img/b-birdie.svg" alt="Birdie" />
        </Link>
        <button className={classes.toggle} type="button" onClick={() => setOpened(opened => !opened)}>
          <Icon.Bars />
        </button>
        <div className={classNames(classes.navbar, opened && classes.opened)}>
          <nav className={classes.nav} onClick={handleClick}>
            {user.hasWorkTypeLocal && (
              <NavLink to="/dashboard" className={classes.link} activeClassName={classes.active}>
                Dashboard
              </NavLink>
            )}
            <NavLink to="/sessions" className={classes.link} activeClassName={classes.active}>
              Ensaios
            </NavLink>
            {user.hasWorkTypeLocal && (
              <NavLink to="/finantial" className={classes.link} activeClassName={classes.active}>
                Financeiro
              </NavLink>
            )}
            <NavLink to="/calendar" className={classes.link} activeClassName={classes.active}>
              Bloqueios
            </NavLink>
            <div className={classes.right}>
              <div className={`${classes.user} ${classes.right}`}>{user.name}</div>
              <Button type="button" onClick={logOut} variant="secondary">
                Sair
              </Button>
            </div>
          </nav>
        </div>
      </div>
    </header>
  )
}
