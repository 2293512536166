import React, { useState } from 'react'

import { FormGroup } from 'shared/form/FormGroupNew'
import { Input } from 'shared/form/Input'
import { InputGroup } from 'shared/form/InputGroup'
import { InputGroupText } from 'shared/form/InputGroupText'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon'
import { formatISO } from 'shared/util/date'
import { toFormData } from 'shared/util/form-data'

import classes from './SessionDeliverableUrlList.module.scss'

interface SessionDeliverableUrlProps {
  product: Resource.SessionResourceProduct<Resource.SessionDeliverableUrl>
  category?: 'admin' | 'client' | 'provider'
  session: Resource.SessionResource
}

export const SessionDeliverableUrlList: React.FC<SessionDeliverableUrlProps> = ({ category, product, session }) => {
  const [current, setCurrent] = useState(product.deliverables || [])
  const [value, setValue] = useState('')
  const handleCopy = (value: string) => (event: React.MouseEvent) => {
    event.preventDefault()
    navigator.clipboard.writeText(value).catch(() => {
      alert('Falha ao copiar para área de transferência')
    })
  }
  const handleDelete = (id: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    fetch(`/api/sessions/${session.id}/${product.id}/videos/${id}`, { method: 'DELETE' }).then(response => {
      if (response.ok) {
        setCurrent(products => [...products.filter(product => product.id !== id)])
      }
    })
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const url = `/api/sessions/${session.id}/${product.id}/urls`
    const body = toFormData({ url: current })
    fetch(url, { body, method: 'POST' }).then(response => {
      if (response.ok) {
        alert('Salvo com sucesso!')
      } else {
        alert('Falha ao adicionar a URL!')
      }
    })
  }
  return (
    <div className={classes.content}>
      <div className={classes.deliverables}>
        {category !== 'client' && (
          <form onSubmit={handleSubmit}>
            <FormGroup className={classes.link} label="Link">
              <InputGroup>
                <InputGroupText>
                  <Icon.Link />
                </InputGroupText>
                <Input type="url" onChange={setValue} value={value} required />
              </InputGroup>
            </FormGroup>
            <Button className={classes.button} size="sm" type="submit">
              ADICIONAR
            </Button>
          </form>
        )}
        <div className={classes.products}>
          {current.map(product => (
            <div key={product.id} className={classes.product}>
              <Icon.Link className={classes.icon} />
              <a className={classes.url} href={product.url} rel="noopener noreferrer" target="_blank">
                <span>{product.title}</span>{' '}
                <span className={classes.createdAt}>({formatISO(product.created_at, 'dd/MM/yyyy HH:mm')})</span>
              </a>
              <button className={classes.copy} onClick={handleCopy(product.url)}>
                <Icon.Clipboard />
              </button>
              {category !== 'client' && (
                <button className={classes.remove} onClick={handleDelete(product.id)}>
                  <Icon.Times />
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
