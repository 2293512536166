import React, { useEffect } from 'react'

import { DropdownItem } from 'shared/bootstrap/dropdown/DropdownItem'

import { useInputSelectContext } from './InputSelect'

interface Props {
  children: string
  className?: string
  value: number | string
}

export const InputSelectItem: React.FC<Props> = ({ children, value, ...props }) => {
  const inputSelectContext = useInputSelectContext()
  const handleClick = () => inputSelectContext.onClick([value, children])
  useEffect(() => {
    inputSelectContext.items.set(value, children)
    // return () => {
    //   inputSelectContext.items.delete(value)
    // }
  }, [children, inputSelectContext, value])
  return <DropdownItem children={children} onClick={handleClick} type="button" {...props} />
}
