import React, { useEffect, useState } from 'react'
import { isPast } from 'date-fns'

import { NavButton } from 'shared/bootstrap/button'
import { Icon } from 'shared/ui/icon'
import { formatDistanceNow, tap, toDate } from 'shared/util'
import { parseAddress } from 'shared/util/google-maps'

import classes from './BottomNavigation.module.scss'

interface Props {
  sessions?: Model.Session[]
}


/** Parece não ser necessário (iOS 15.2)
function getMarginBottom() {
  const body = document.body.getBoundingClientRect()
  const viewportHeight = body.bottom + body.top
}

const [translateY, setTranslateY] = useState()
const [node, setNode] = useState<HTMLDivElement>()
const ref = useCallback<(_: HTMLDivElement) => void>(element => {
  setNode(element)
}, [])
useEffect(() => {
  const handleResize = () => { }
}, [node]) */

export const BottomNavigation: React.FC<Props> = ({ sessions }) => {
  const [index, setIndex] = useState(0)
  const [now, setNow] = useState(new Date())
  const handleIndex = (add: number) => () => setIndex(index => index + add)
  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 10 * 60 * 1000)
    return () => clearInterval(interval)
  }, [])
  useEffect(() => {
    if (sessions === undefined || sessions.length === 0) {
      setIndex(0)
    } else {
      const index = [...sessions].reverse()
        .map(({ scheduled_for }) => toDate(scheduled_for))
        .findIndex(date => date && isPast(date))
      if (index >= 0) {
        setIndex(sessions.length - Math.max(index, 0))
      } else {
        setIndex(1)
      }
    }
  }, [now, sessions])
  if (sessions === undefined || sessions.length === 0) {
    return null
  }
  return (
    <div className={classes.navigation}>
      <button className={classes.btn} disabled={index <= 1} onClick={handleIndex(-1)} type="button">
        <Icon.AngleLeft />
      </button>
      <div className={classes.sessions}>
        <div className={classes.list} style={{transform: `translateX(${(index - 1) * -100}%)`}}>
          {sessions.map(session => {
            const address = parseAddress(session.address?.address_components)
            const scheduledFor = toDate(session.scheduled_for)
            const relativeScheduledFor = formatDistanceNow(scheduledFor)
            return (
              <div key={session.id} className={classes.session}>
                {scheduledFor && (
                  <div className={classes.date}>
                    {isPast(scheduledFor) ? `há ${relativeScheduledFor}`: `em ${relativeScheduledFor}`}
                  </div>
                )}
                <NavButton className={classes.button} size="sm" to={`/sessions/${session.id}`}>
                  <div className={classes.id}>{session.id}</div>
                  {address.has('street') && (
                    <div className={classes.address}>
                      {address.get('street')}
                      {address.has('number') && `, ${address.get('number')}`}
                    </div>
                  )}
                </NavButton>
              </div>
            )
          })}
        </div>
      </div>
      <button className={classes.btn} disabled={index >= sessions.length} onClick={handleIndex(1)} type="button">
        <Icon.AngleRight />
      </button>
    </div>
  )
}