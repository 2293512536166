import React, { Fragment, useState } from 'react'

import { Card, CardBody, CardFooter } from 'shared/bootstrap/card'
import { useModal } from 'shared/bootstrap/modal'
import { SessionStatusLabel } from 'shared/components/sessions/SessionStatusLabel'
import { FormCheck } from 'shared/form/FormCheck'
import { useSessions } from 'shared/hooks/data/session'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon'
import { Table, TableBody, TableColumn, TableHeader, TablePaginator, TableRow } from 'shared/ui/table'
import { tap } from 'shared/util'
import { classNames } from 'shared/util/class-names'
import { formatDateTimeString } from 'shared/util/date'
import { toFormData } from 'shared/util/form-data'
import { parseAddress } from 'shared/util/google-maps'
import { useSkipEffect } from 'shared/util/hooks'

import { useAuth } from 'components/auth/AuthContext'

import { FinantialReport } from './FinantialReport'

import classes from './FinantialSessionList.module.scss'

interface ResponseMeta extends Http.ResponseMeta {
  finantial_reports: {
    [key: string]: {
      approved: boolean
      created_at: string
      day: string
      notes: string | null
    }
  }
}

function formatParams(confirmed: boolean) {
  return {
    filter: { confirmed, scope: 'provider-confirm' },
    order: ['scheduled_for', 'desc'],
    with: {
      /* finantial_reports: scheduledFor */
    }
  }
}

export const FinantialSessionList: React.FC = () => {
  const [confirmedTab, setConfirmedTab] = useState(false)
  const [meta, setMeta] = useState<ResponseMeta>()
  const [paging, setPaging] = useState<Http.Pagination>({ page: 1, length: 50 })
  const [params, setParams] = useState(formatParams(confirmedTab))
  const [selected, setSelected] = useState(new Set<string>())
  const auth = useAuth()
  const sessions = useSessions(params, paging, setMeta)
  const reportModal = useModal(FinantialReport)
  const handleCheckAll = () => {
    setSelected(selected =>
      tap(new Set(selected), selected => {
        if (sessions) {
          sessions.forEach(({ id, products }) => {
            products?.forEach(product => {
              if (product.item.work_type === 2 && product.provider?.id === auth?.provider.id) {
                selected.add(`${id}-${product.id}`)
              }
            })
          })
        }
      })
    )
  }
  const handleConfirm: React.MouseEventHandler = event => {
    event.currentTarget.setAttribute('disabled', 'disabled')
    const sessions = Array.from(selected, (session, i) => {
      const [sessionId, productId] = session.split('-')
      const body = toFormData({
        confirmed: true
      })
      const request = () => fetch(`/api/sessions/${sessionId}/${productId}`, { body, method: 'POST' })
      return new Promise<Response>(resolve => {
        setTimeout(() => resolve(request()), i * 200)
      })
    })
    Promise.allSettled(sessions).finally(() => {
      window.location.reload()
    })
  }
  const handleReportModal = () => reportModal.current?.open()
  useSkipEffect(1, () => setParams(formatParams(confirmedTab)), [confirmedTab])
  return (
    <Card>
      <CardBody className={classes.body}>
        <div className={classes.header}>
          <nav className={classes.nav}>
            <button
              className={classNames(classes.link, !confirmedTab && classes.active)}
              onClick={() => setConfirmedTab(false)}
              type="button"
            >
              Ensaios a confirmar
            </button>
            <button
              className={classNames(classes.link, confirmedTab && classes.active)}
              onClick={() => setConfirmedTab(true)}
              type="button"
            >
              Ensaios confirmados
            </button>
          </nav>
          {!confirmedTab && (
            <Fragment>
              <Button size="sm" type="button" outline onClick={handleCheckAll}>
                SELECIONAR ENSAIOS
              </Button>
              <Button disabled={selected.size === 0} size="sm" type="button" onClick={handleConfirm}>
                CONFIRMAR SELECIONADOS {selected.size > 0 && `(${selected.size})`}
              </Button>
            </Fragment>
          )}
        </div>
        {sessions && (
          <Table dense hover strip>
            <TableHeader>
              <TableColumn align="center">ID</TableColumn>
              <TableColumn align="center" size={2}>
                Cliente
              </TableColumn>
              <TableColumn align="center" size={2}>
                Ensaio
              </TableColumn>
              <TableColumn align="center">Valor</TableColumn>
              <TableColumn align="center">Área</TableColumn>
              <TableColumn align="center" size={2}>
                Serviço
              </TableColumn>
              <TableColumn align="center">Status</TableColumn>
              {!confirmedTab && <TableColumn align="center"></TableColumn>}
              {/*<TableColumn align="center">SLA</TableColumn>*/}
            </TableHeader>
            <TableBody>
              {sessions.map(session => {
                const address = parseAddress(session.address?.address_components)
                const hasArea = session.vertical_data.area && !isNaN(session.vertical_data.area)
                const area = session.vertical_data.area?.toLocaleString('pt-BR', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })
                const products = Array.from(session.products || []).filter(product => {
                  return product.item.work_type === 2 && product.provider?.id === auth?.provider.id
                })
                const checked = products.reduce((included, { id }) => {
                  return included && selected.has(`${session.id}-${id}`)
                }, products.length > 0)
                const cost = products.reduce((total, product) => total + (product.cost || 0), 0)
                const handleCheck = (checked: boolean) => {
                  setSelected(selected =>
                    tap(new Set(selected), selected => {
                      products.forEach(({ id }) => {
                        if (checked) {
                          selected.add(`${session.id}-${id}`)
                        } else {
                          selected.delete(`${session.id}-${id}`)
                        }
                      })
                    })
                  )
                }
                return (
                  <TableRow key={session.id}>
                    <TableColumn align="center">
                      <Button className={classes.id} size="sm" to={`/sessions/${session.id}`} target="_blank">
                        <span>{session.id}</span>
                        <Icon.AngleRight />
                      </Button>
                    </TableColumn>
                    <TableColumn align="center" size={2}>
                      <div>{session.client.name}</div>
                    </TableColumn>
                    <TableColumn align="center" size={2}>
                      <div>
                        {address.get('street')}
                        {address.has('number') && `, ${address.get('number')}`}
                        {session.vertical_data?.unit && ` - ${session.vertical_data?.unit}`}
                      </div>
                      <div>{formatDateTimeString(session.scheduled_for!)}</div>
                    </TableColumn>
                    <TableColumn align="center">
                      R$ {(cost || 0).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                    </TableColumn>
                    <TableColumn align="center">{hasArea ? `${area} m²` : '-'}</TableColumn>
                    <TableColumn align="center" size={2}>
                      {products.map(product => (
                        <div key={product.id}>{product.item.label}</div>
                      ))}
                    </TableColumn>
                    <TableColumn align="center">
                      {/*products.map(product => (
                        <div key={product.id}>{getServiceProductSessionStatusLabel(product.status)}</div>
                      ))*/}
                      <SessionStatusLabel status={session.status} />
                    </TableColumn>
                    {!confirmedTab && (
                      <TableColumn>
                        <FormCheck checked={checked} onChange={handleCheck} />
                      </TableColumn>
                    )}
                    {/*<TableColumn align="center">{session.service.sla || '-'}</TableColumn>*/}
                  </TableRow>
                )
              })}
            </TableBody>
            <TablePaginator meta={meta} onChange={(page, length) => setPaging({ page, length })} />
          </Table>
        )}
      </CardBody>
      <CardFooter justify="flex-end">
        <Button outline size="sm" type="button" variant="warning" onClick={handleReportModal}>
          SOLICITAR CORREÇÃO
        </Button>
      </CardFooter>
    </Card>
  )
}
