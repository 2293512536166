import * as React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Button } from 'shared/ui/button/Button'
import { Card } from 'shared/ui/card/Card'
import { Container } from 'shared/ui/container/Container'

import classes from './CalendarList.module.scss'
import { CalendarListTable } from './components/CalendarListTable'

export const CalendarList: React.FC = () => {
  return (
    <>
      <Container fluid="xl">
        <div className={classes.flexBetween}>
          {/*<Breadcrumb>*/}
          {/*<Link to="/calendar" style={{color:'#000'}}>*/}
            {/*<BreadcrumbItem>Calendário</BreadcrumbItem>*/}
          {/*</Link>*/}
          {/*</Breadcrumb>*/}
          <Button
              onClick={() => {
                console.log('Criar um bloqueio na agenda')
              }}
              to="calendar/block"
              size="sm"
              className={classes.my16}>
            Criar um bloqueio
          </Button>
        </div>
        <Card className={classes.content} shadow>
          <CalendarListTable /* className={classes.table} */ />
        </Card>
      </Container>
    </>
  )
}
