import React, { useEffect, useRef } from 'react'

interface SessionsMapProps {
  className?: string
  sessions?: Model.Session[]
}

function isValidLocation(location?: google.maps.LatLng) {
  return !isNaN(Number(location?.lat)) && !isNaN(Number(location?.lng))
}

export const SessionsMap: React.FC<SessionsMapProps> = ({ className, sessions = [] }) => {
  const container = useRef<HTMLDivElement>(null)
  const directions = useRef<google.maps.DirectionsService>()
  const lines = useRef<google.maps.Polyline[]>([])
  const map = useRef<google.maps.Map>()
  const markers = useRef<google.maps.Marker[]>([])
  const renderer = useRef<google.maps.DirectionsRenderer>()
  useEffect(() => {
    if (container.current && !map.current) {
      map.current = new google.maps.Map(container.current, {
        center: { lat: -25.344, lng: 131.036 },
        zoom: 6,
        gestureHandling: 'greedy',
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false
      })
      directions.current = new google.maps.DirectionsService()
      renderer.current = new google.maps.DirectionsRenderer({
        map: map.current!
      })
    }
    markers.current.forEach(marker => marker.setMap(null))
    markers.current = sessions
      .filter(session => isValidLocation(session.address?.geometry?.location))
      .map(
        session =>
          new google.maps.Marker({
            icon: {
              scaledSize: new google.maps.Size(25, 35),
              url: '/img/pin.png'
            },
            map: map.current!,
            position: {
              lat: Number(session.address!.geometry!.location.lat),
              lng: Number(session.address!.geometry!.location.lng)
            }
          })
      )
    lines.current.forEach(line => line.setMap(null))
    const points = sessions
      .filter(session => isValidLocation(session.address?.geometry?.location))
      .map(session => session.address!.geometry!.location)
      .map(location => ({
        lat: Number(location.lat),
        lng: Number(location.lng)
      }))
    if (points.length > 1) {
      const routeRequest = {
        destination: points[points.length - 1],
        origin: points[0],
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: points.slice(1, points.length - 1).map(point => ({
          location: new google.maps.LatLng(point.lat, point.lng),
          stopover: true
        }))
      }
      directions.current!.route(routeRequest, (result, status) => {
        if (status === 'OK') {
          renderer.current!.setDirections(result)
        }
      })
    }
    const bounds = new google.maps.LatLngBounds()
    markers.current.forEach(marker => bounds.extend(marker.getPosition()!))
    map.current!.fitBounds(bounds)
  }, [sessions])
  return <div className={className} ref={container} />
}
