import React from 'react'

import classes from './Recover.module.scss'

export const Done: React.FC = () => {
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.logo}>
          <img src="/img/logo-birdie.svg" alt="Logo Birdie" />
        </div>
        <div className={classes.form}>
          <div className={classes.content}>
            <h1 className={classes.title}>Pronto!</h1>
            <p>Uma nova senha foi enviada para o e-mail informado!</p>
          </div>
        </div>
      </div>
    </div>
  )
}
