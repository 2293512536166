import React from 'react'

import { classNames } from '../util/class-names'

import classes from './FormGroup.module.scss'

interface FormGroupProps {
  className?: string
  label?: string
  required?: boolean
}

export const FormGroup: React.FC<FormGroupProps> = ({ className, children, label, required }) => {
  return (
    <div className={classNames(classes.group, className, 'form-group')}>
      {label && (
        <label className={classes.label}>
          {label}
          {required && `*`}
        </label>
      )}
      {children}
    </div>
  )
}
