import React from 'react'

import { Card, CardBody } from 'shared/bootstrap/card'

import classes from './SessionReport.module.scss'

interface Props {
  report: Omit<Model.SessionReport, 'id'>
}

export const SessionReport: React.FC<Props> = ({ report }) => {
  return (
    <Card margin="top" shadow>
      <CardBody>
        <h6 className={classes.heading}>Relato do ensaio</h6>
        <div>
          <p className={classes.info}>{report.notes || 'Não informado'}</p>
          {report.additional_info?.displacement === true && (
            <p className={classes.info}>
              <strong>Cancelado com deslocamento</strong>
            </p>
          )}
          {report.additional_info?.displacement === false && (
            <p className={classes.info}>
              <strong>Cancelado sem deslocamento</strong>
            </p>
          )}
        </div>
        {report.additional_info?.lacking_areas && (
          <div>
            <p className={classes.title}>Ambientes que faltaram</p>
            <p className={classes.info}>{report.additional_info?.lacking_areas || '-'}</p>
          </div>
        )}
      </CardBody>
    </Card>
  )
}
