import React, { Fragment, useEffect, /*useRef,*/ useState } from 'react'

import { useDataSource } from '../hooks/source/BaseDataSource'
import { Icon } from '../ui/icon'
import { classNames } from '../util/class-names'

import { DropdownItem } from '../bootstrap/dropdown/DropdownItem'
import { DropdownMenu } from '../bootstrap/dropdown/DropdownMenu'

import { useFormGroupContext } from './FormGroupNew'

import classes from './InputAutocomplete.module.scss'

interface Props {
  className?: string
  disabled?: boolean
  defaultValue?: string
  empty?: boolean | string
  onChange?: (_: number | string) => void
  placeholder?: string
  renderItem?: <T>(_: T) => React.ReactElement | null
  size?: 'sm' | 'lg'
  type?: 'email' | 'text'
  value?: string
}

const clickEvent = new Set<(event: MouseEvent) => void>()
const escEvent = new Set<() => void>()

window.addEventListener('click', event => {
  clickEvent.forEach(callback => callback(event))
})

window.addEventListener('keyup', event => {
  if (event.key === 'Escape') {
    escEvent.forEach(callback => callback())
  }
})

export const InputAutocomplete: React.FC<Props> = ({ className, defaultValue, empty, onChange, renderItem, size, ...props }) => {
  const [dirty, setDirty] = useState(false)
  const [opened, setOpened] = useState(false)
  const [selected, setSelected] = useState('')
  const [value, setValue] = useState('')
  const dataSource = useDataSource()
  const formGroup = useFormGroupContext()
  const params = {
    autoComplete: 'off',
    className: classNames('form-control', size && `form-control-${size}`, classes.input),
    id: formGroup?.id,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      dataSource.onChange(event.target.value)
      setDirty(true)
      setSelected('')
      setValue(event.target.value)
    },
    onFocus: () => setOpened(true),
    type: 'search',
    value: (!dirty && defaultValue) || selected || value
  }
  const handleBackdrop = () => setOpened(false)
  const handleClear = () => {
    dataSource.onChange('')
    onChange?.('')
    setDirty(true)
    setOpened(false)
    setSelected('')
    setValue('')
  }
  const handleClick = (key: number | string, value: string) => () => {
    onChange?.(key)
    setOpened(false)
    setSelected(value)
    setValue(value)
  }
  useEffect(() => {
    const handleEscape = () => setOpened(false)
    escEvent.add(handleEscape)
    return () => {
      escEvent.delete(handleEscape)
    }
  }, [])
  useEffect(() => {
    if (props.disabled !== true) {
      // TODO: verificar se dá pra ser removido
      dataSource.onChange(value)
    }
  }, [props.disabled])
  return (
    <div className={classNames(classes.autocomplete, className)}>
      <div className={classNames(classes.backdrop, opened && classes.visible)} onClick={handleBackdrop} />
      <div className={classes.input}>
        <input {...params} {...props} />
        <button className={classNames(classes.clear, (defaultValue || value) && classes.visible)} onClick={handleClear} type="button">
          <Icon.Times />
        </button>
      </div>
      <DropdownMenu className={classes.menu} opened={opened}>
        {dataSource.items ? (
          dataSource.items.length ? (
            <Fragment>
              {empty && <DropdownItem className={classes.empty}>{empty === true ? 'Selecione' : empty}</DropdownItem>}
              {dataSource.items.map(([key, value]) => (
                <DropdownItem key={key} onClick={handleClick(key, `${value}`)}>
                  {renderItem?.(value) || value}
                </DropdownItem>
              ))}
            </Fragment>
          ) : (
            <div>Nenhum resultado encontrado.</div>
          )
        ) : (
          <div className={classes.loader}>
            <Icon.CircleNotch spin />
          </div>
        )}
      </DropdownMenu>
    </div>
  )
}
