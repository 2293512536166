import React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { NavButton } from 'shared/bootstrap/button'
import { Container } from 'shared/ui/container/Container'

import { FinantialUploadInvoice } from './finantial-upload-invoice/FinantialUploadInvoice'
import { FinantialSessionList } from './finantial-session-list/FinantialSessionList'
import { FinantialSessionMonth } from './finantial-session-month/FinantialSessionMonth'

import classes from './Finantial.module.scss'

export const Finantial: React.FC<RouteComponentProps> = ({ match }) => (
  <Container>
    <div className={classes.header}>
      <NavButton to={`${match.path}`} exact outline variant="dark">
        Ensaios realizados
      </NavButton>
      <NavButton to={`${match.path}/month`} outline variant="dark">
        Ensaios por mês
      </NavButton>
      <NavButton to={`${match.path}/invoice`} outline variant="dark">
        Envio de notas fiscais
      </NavButton>
    </div>
    <Switch>
      <Route path={`${match.path}/month`} component={FinantialSessionMonth} />
      <Route path={`${match.path}/invoice`} component={FinantialUploadInvoice} />
      <Route path={`${match.path}/:section(confirmed|pending)?`} exact component={FinantialSessionList} />
      <Redirect to="/finantial" />
    </Switch>
  </Container>
)
