import React, { useState } from 'react'

import { FormGroup } from 'shared/form/FormGroupNew'
import { Input } from 'shared/form/Input'
import { InputGroup } from 'shared/form/InputGroup'
import { InputGroupText } from 'shared/form/InputGroupText'
import { Button } from 'shared/ui/button/Button'
import { RoundButton } from 'shared/ui/button/RoundButton'
import { Icon } from 'shared/ui/icon'
import { toFormData } from 'shared/util/form-data'

import classes from './SessionDeliverableTour.module.scss'

interface Props {
  category: 'admin' | 'client' | 'provider'
  onChange?: () => void
  product: Resource.SessionResourceProduct<Resource.SessionResourceDeliverableTour>
  session: Resource.SessionResource
}

interface Deliverable {
  id?: number
  birdie_url?: string
  matterport_url?: string
  host_prize?: string
}

const defaultTour = () => ({
  birdie_url: '',
  matterport_url: '',
  host_prize: ''
})

export const SessionDeliverableTour: React.FC<Props> = ({ category, product, session }) => {
  const [tours, setTours] = useState<Deliverable[]>(() => {
    if (product.deliverables.length) {
      return product.deliverables.map(tour => ({
        id: tour.id,
        birdie_url: tour.birdie_url,
        matterport_url: tour.matterport_url,
        host_prize: tour.host_prize?.toString() || ''
      }))
    } else {
      return [defaultTour()]
    }
  })
  const handleLinkCopy = (url: string) => () => navigator.clipboard.writeText(url)
  const handleLinkOpen = (url: string) => () => window.open(url)
  const handleAdd = (event: React.MouseEvent) => {
    event.preventDefault()
    setTours(tours => [...tours, defaultTour()])
  }
  const handleChange = (i: number) => (key: string) => (value: string) => {
    setTours(tours => [...tours.slice(0, i), { ...tours[i], [key]: value }, ...tours.slice(i + 1)])
  }
  const handleDelete = (i: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    const tour = tours[i]
    if (tour.id) {
      const url = `/api/sessions/${session.id}/${product.id}/tours/${tour.id}`
      fetch(url, { method: 'DELETE' })
        .then(response => {
          if (response.ok) {
            const index = tours.findIndex(({ id }) => id === tour.id)
            setTours(tours => [...tours.slice(0, index), ...tours.slice(index + 1)])
          } else {
            throw new Error()
          }
        })
        .catch(() => alert('Falha ao remover tour!'))
    } else {
      setTours(tours => [...tours.slice(0, i), ...tours.slice(i + 1)])
    }
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const baseUrl = `/api/sessions/${session.id}/${product.id}/tours`
    const requests = tours.map(({ id, ...tour }, i) => {
      const url = baseUrl + ((id && `/${id}`) || '')
      const body = toFormData({ ...tour, order: i })
      return fetch(url, { body, method: 'POST' }).then(async response => {
        if (response.ok) {
          const { data } = await response.json()
          setTours(tours => [...tours.slice(0, i), { ...tours[i], id: data.id }, ...tours.slice(i + 1)])
        } else {
          throw new Error()
        }
      })
    })
    Promise.all(requests)
      .then(() => alert('Salvo com sucesso!'))
      .catch(() => alert('Falha ao salvar os tours!'))
  }
  return (
    <form onSubmit={handleSubmit}>
      {tours.map((tour, i) => (
        <div className={classes.urls} key={i}>
          {category !== 'client' && (
            <FormGroup className={classes.link} label="URL do Tour Virtual (Matterport)">
              <Input type="url" onChange={handleChange(i)('matterport_url')} value={tour.matterport_url} />
            </FormGroup>
          )}
          <div className={classes.wrapper}>
            <FormGroup className={classes.link} label="URL do Tour Virtual (Birdie)">
              <Input type="url" onChange={handleChange(i)('birdie_url')} value={tour.birdie_url} />
            </FormGroup>
            <RoundButton type="button" onClick={handleLinkCopy(tour.birdie_url || '')}>
              <Icon.Clipboard />
            </RoundButton>
            <RoundButton type="button" onClick={handleLinkOpen(tour.birdie_url || '')}>
              <Icon.ExternalLinkAlt />
            </RoundButton>
          </div>
          <FormGroup className={classes.hostPrize} label="Prazo de hospedagem">
            <InputGroup>
              <Input onChange={handleChange(i)('host_prize')} value={tour.host_prize?.toString()} />
              <InputGroupText>meses</InputGroupText>
            </InputGroup>
          </FormGroup>
          <RoundButton className={classes.delete} onClick={handleDelete(i)}>
            <Icon.Times />
          </RoundButton>
        </div>
      ))}
      {category !== 'client' && (
        <div className={classes.buttons}>
          <Button onClick={handleAdd} outline size="sm" variant="info">
            ADICIONAR TOUR
          </Button>
          <Button size="sm" type="submit">
            SALVAR
          </Button>
        </div>
      )}
    </form>
  )
}

/*<div className={classes.custom}>
        <p className={classes.title}>Personalizar o Tour Virtual</p>
        <div className={classes.fields}>
          <div className={classes.info}>
            <FormGroup label="Nome">
              <FormText onChange={handleChange('tour_name')} value={current.tour_name} />
            </FormGroup>
            <FormGroup label="Endereço">
              <FormText onChange={handleChange('tour_address')} value={current.tour_address} />
            </FormGroup>
            <FormGroup label="Nome para contato">
              <FormText onChange={handleChange('tour_contact_name')} value={current.tour_contact_name} />
            </FormGroup>
            <FormGroup label="E-mail do contato">
              <FormText type="email" onChange={handleChange('tour_contact_email')} value={current.tour_contact_email} />
            </FormGroup>
            <FormGroup label="Apresentado por">
              <FormText onChange={handleChange('tour_presented_by')} value={current.tour_presented_by} />
            </FormGroup>
            <FormGroup label="Link externo">
              <FormText type="url" onChange={handleChange('tour_external_url')} value={current.tour_external_url} />
            </FormGroup>
            <FormGroup label="Telefone do contato">
              <FormMasked
                mask="phone"
                onChange={handleChange('tour_contact_phone')}
                value={current.tour_contact_phone}
              />
            </FormGroup>
            <FormGroup label="Descrição">
              <FormText onChange={handleChange('tour_description')} value={current.tour_description} />
            </FormGroup>
          </div>
          {session.client.white_label === true && (
            <div className={classes.logo}>
              <p className={classes.title}>Logo do cliente</p>
              <FormGroup label="Nome do cliente">
                <FormText onChange={handleChange('white_label_name')} value={current.white_label_name} />
              </FormGroup>
              <FormGroup label="Logo">
                <FormLogo onChange={handleLogo} />
              </FormGroup>
              <button className={classes.link}>Escolher logo existente →</button>
            </div>
          )}
        </div>
      </div>*/
