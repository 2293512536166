import React, { useState } from 'react'

import { Button } from 'shared/bootstrap/button'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/bootstrap/modal'
import { InputTextArea } from 'shared/form/InputTextArea'
import { FormGroup } from 'shared/form/FormGroupNew'
import { Icon } from 'shared/ui/icon'
import { classNames } from 'shared/util/class-names'
import { toFormData } from 'shared/util/form-data'


import classes from './SessionDeliverableRateModal.module.scss'

interface Props {
  product?: Resource.SessionResourceProduct
  session: Resource.SessionResource
}

type Value = Partial<Pick<Model.ServiceProductSession, 'rate' | 'rate_description'>>

export const SessionDeliverableRateModal: React.FC<Props> = ({ product, session }) => {
  const [value, setValue] = useState<Value>({})
  const handleChange = (key: keyof Value) => (value: number | string) => {
    setValue(v => ({ ...v, [key]: value }))
  }
  const handleSave = (event: React.MouseEvent) => {
    event.preventDefault()
    const body = toFormData(value)
    fetch(`/api/sessions/${session.id}/${product?.id}`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          window.location.reload()
        } else {
          throw Error()
        }
      })
      .catch(() => alert('Falha ao salvar avaliação!'))
  }
  return (
    <Modal>
      <ModalHeader title="Avaliar entregável do ensaio" />
      <ModalBody>
        <div className={classes.rating}>
          <span>Avaliação</span>
          <div className={classes.stars}>
            {Array.from({length: 5}).map((_, i) => (
              <button
                key={i}
                className={classNames((value.rate || 0) > i && classes.checked)}
                onClick={() => handleChange('rate')(i + 1)}
              >
                <Icon.StarRegular className={classes.regular} />
                <Icon.Star className={classes.filled} />
              </button>
            ))}
          </div>
        </div>
        <FormGroup label="Observações">
          <InputTextArea onChange={handleChange('rate_description')} initial={product?.rate_description} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button enabled={Number(value.rate) > 0} onClick={handleSave} size="sm">
          SALVAR
        </Button>
      </ModalFooter>
    </Modal>
  )
}
