import React, { Fragment } from 'react'

import { getSessionStatusColor, getSessionStatusDescription } from 'shared/enum/session/SessionStatus'
import { Badge } from 'shared/ui/badge/Badge'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon/Icon'

import { useAuth } from 'components/auth/AuthContext'

import classes from './SessionListColumnID.module.scss'

interface SessionListColumnIDProps {
  session: Model.Session
}

export const SessionListColumnID: React.FC<SessionListColumnIDProps> = ({ session }) => {
  const auth = useAuth()
  const canView =
    auth?.hasWorkTypeLocal ||
    session.products?.find(({ provider }) => {
      return provider?.id === auth?.provider.id
    })
  return (
    <Fragment>
      {canView ? (
        <Button className={classes.id} size="sm" to={`/sessions/${session.id}`}>
          <span>{session.id}</span>
          <Icon.AngleRight />
        </Button>
      ) : (
        <div className={classes.id}>
          <span>{session.id}</span>
        </div>
      )}
      <div className={classes.status}>
        <Badge color={getSessionStatusColor(session.status)} size="sm">
          {getSessionStatusDescription(session.status)}
        </Badge>
      </div>
    </Fragment>
  )
}
