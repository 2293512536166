import * as React from 'react'
import {Table, TableBody, TableColumn, TableHeader, TableRow} from 'shared/ui/table'

import classes from './CalendarListTable.module.scss'
import {useEffect, useState} from "react";
import {getFrequencyBlockDescription} from "../../../../shared/enum/provider/calendar/FrequencyBlock";
import {Button} from "../../../../shared/ui/button";
import {useToast, useDisclosure} from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Flex,
    VStack
} from '@chakra-ui/react'


interface SessionListTableProps {
    className?: string
    filter?: { [key: string]: any }
}

interface BlockInterface {
    id: number,
    frequency: number,
    starts_at: string,
    ends_at: string,
    reason: string,
    created_at: string,
    deleted_at: string,
    request_precedence: string,
    cancel_precedence: string
}

export const CalendarListTable: React.FC<SessionListTableProps> = ({className, filter}) => {

    const toast = useToast()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [selectBlock, setSelectBlock] = useState<any>();

    const [data, setData] = useState<BlockInterface[]>([]);
    const [refresh, setRefresh] = useState<number>();

    useEffect(() => {
        fetch('/api/provider/calendar/block')
            .then(response => response.json())
            .then(({data}) => {
                setData(data)
            })
    }, [refresh])

    const deleteBlock = (id: number) => {

        fetch(`/api/provider/calendar/block/${id}`, {method: 'delete'})
            .then(response => response.json())
            .then(response => {
                onClose()
            if (response?.success) {
                setRefresh(id)
                toast({
                    title: 'Bloqueio cancelado com sucesso.',
                    position: 'top-right',
                    // description: "We've created your account for you.",
                    status: 'success',
                    duration: 8000,
                    isClosable: true,
                })
            } else {
                setRefresh(id)
                if (response?.error){
                    toast({
                        title: 'Não foi possivel cancelar o bloqueio.',
                        position: 'top-right',
                        description: response?.message,
                        status: 'error',
                        duration: 9500,
                        isClosable: true,
                    })
                }
            }
        })
    }

    return (
        <>
            <Table className={className} hover strip>
                <TableHeader>
                    <TableColumn align="center">Código do Bloqueio</TableColumn>
                    <TableColumn align="flex-start">Data inicio </TableColumn>
                    <TableColumn align="flex-start">Data fim</TableColumn>
                    <TableColumn align="flex-start">Motivo</TableColumn>
                    <TableColumn align="center">Status</TableColumn>
                    <TableColumn align="center">Cancelar bloqueio</TableColumn>
                </TableHeader>
                <TableBody>
                    {
                        data?.map(block => {
                            return (
                                <TableRow>
                                    <TableColumn align="center">{block.id}</TableColumn>
                                    <TableColumn align="flex-start">
                    <span className={classes.tableRow}>{new Date(block.starts_at).toLocaleDateString('pt-BR', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    })}</span>
                                    </TableColumn>
                                    <TableColumn align="flex-start">
                    <span className={classes.tableRow}>{new Date(block.ends_at).toLocaleDateString('pt-BR', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    })}</span>
                                    </TableColumn>
                                    <TableColumn
                                        align="flex-start"> {block.reason}</TableColumn>
                                    <TableColumn align="center">
                                        {
                                            block.deleted_at &&
                                            <div><strong>Cancelado</strong><br />
                                                <span className={classes.helpDate}>cancelado em {new Date(block.deleted_at).toLocaleDateString('pt-BR', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                })}</span>
                                                <br />
                                                <span className={classes.helpPrecedence}>
                                                    {block.cancel_precedence} antecedência
                                                </span>
                                            </div>
                                        }
                                        {
                                            !block.deleted_at &&
                                            <div><strong>Ativo</strong><br />
                                                <span className={classes.helpDate}>criado em {new Date(block.created_at).toLocaleDateString('pt-BR', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                })}</span>
                                                <br />
                                                <span className={classes.helpPrecedence}>
                                                    {block.request_precedence} antecedência
                                                </span>
                                            </div>
                                        }
                                    </TableColumn>
                                    <TableColumn align="center">
                                        {
                                            !block.deleted_at &&
                                            <Button onClick={() => {
                                                onOpen()
                                                setSelectBlock(block)
                                            }}>Cancelar</Button>
                                        }
                                    </TableColumn>
                                </TableRow>
                            )
                        })
                    }

                </TableBody>
            </Table>

            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Confirmar bloqueio</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <VStack spacing={'4'}>
                            <h1>
                                Você deseja remover esse bloqueio:
                            </h1>

                            <VStack spacing={'1'}>
                                <h1>{selectBlock?.starts_at && new Date(selectBlock.starts_at).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}</h1>
                                <h1>até</h1>
                                <h1>{selectBlock?.ends_at && new Date(selectBlock.ends_at).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}</h1>
                            </VStack>
                        </VStack>

                    </ModalBody>

                    <ModalFooter>
                        <Flex justify={'center'} w={'100%'}>
                            <Button type={'button'} onClick={() => deleteBlock(selectBlock.id)}>Cancelar o bloqueio</Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
