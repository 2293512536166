export enum FrequencyBlock {
  DOES_NOT_REPEAT = 0,
  WEEKLY = 1
}

export function getFrequencyBlockColor(frequency: FrequencyBlock) {
  switch (frequency) {
    default:
      return ''
  }
}

export function getFrequencyBlockDescription(frequency: FrequencyBlock) {
  switch (frequency) {
    case FrequencyBlock.DOES_NOT_REPEAT:
      return 'Não se repete'
    case FrequencyBlock.WEEKLY:
      return 'Semanal'
  }
}
