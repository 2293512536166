import * as React from 'react'
import { Button } from 'shared/bootstrap/button'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/bootstrap/modal'
import { Icon } from 'shared/ui/icon'
import { FormGroup } from 'shared/bootstrap/form/FormGroup'
import { FormTextArea } from 'shared/form/FormTextArea'

import classes from './CalendarBlockConfirm.module.scss'
import { useState } from 'react'

interface Props {}

export const CalendarBlockConfirm: React.FC<Props> = () => {
  const [text, setText] = useState()

  const handleText = (event: any) => {
    setText(event.target.value)
  }
  const handleSubmitModal = () => {
    console.log(text)
  }
  return (
    <Modal>
      <ModalHeader title="Nos ajude avaliando a  nova funcionalidade!" />
      <ModalBody>
        <div className={classes.rating}>
          <div className={classes.my16}>
            <div className={classes.titles}>Quanto essa nova ferramenta facilita seu trabalho?</div>
          </div>

          <div className={classes.stars}>
            <Icon.Star className={classes.filled} />
            <Icon.Star className={classes.filled} />
            <Icon.Star className={classes.filled} />
            <Icon.StarRegular className={classes.regular} />
            <Icon.StarRegular className={classes.regular} />
          </div>
        </div>
        <hr />
        <div>
          <div className={classes.my16}>
            <div className={classes.titles}>Fala que a gente te escuta :)</div>
          </div>

          <FormGroup label="Deixe um comentário abaixo" className={classes.my16}>
            <FormTextArea onChange={handleText}/>
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSubmitModal}>ENVIAR</Button>
      </ModalFooter>
    </Modal>
  )
}
