import React, { useState } from 'react'

import { useAuthContext } from 'components/auth/AuthContext'
import { Icon } from 'shared/ui/icon'
import { Button } from 'shared/ui/button/Button'

import classes from './Login.module.scss'

export const Login: React.FC = () => {
  const [, setUser] = useAuthContext()
  const [state, setState] = useState({
    email: '',
    password: ''
  })
  const handleChange = (key: keyof typeof state) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setState(state => ({ ...state, [key]: value }))
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const data = new FormData()
    data.set('email', state.email)
    data.set('password', state.password)
    const request = {
      body: data,
      method: 'POST'
    }
    fetch(`/api/auth/login`, request)
      .then(response => response.json())
      .then(({ data }) => data)
      .then(user => {
        if (user?.scope !== 'provider') {
          return fetch(`/api/auth/logout`, { method: 'POST' }).then(() => setUser(null))
        } else {
          setUser(user)
        }
      })
      .catch(() => alert('Falha no login'))
  }
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.logo}>
          <img src="/img/logo-birdie.svg" alt="Logo Birdie" />
        </div>
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.content}>
            <h1 className={classes.title}>Faça seu login</h1>
            <div className={classes.input}>
              <span className={classes.icon}>
                <Icon.Envelope />
              </span>
              <input
                type="email"
                placeholder="E-mail"
                autoComplete="email"
                onChange={handleChange('email')}
                value={state.email}
                required
              />
            </div>
            <div className={classes.input}>
              <span className={classes.icon}>
                <Icon.Lock />
              </span>
              <input
                type="password"
                placeholder="Senha"
                autoComplete="current-password"
                onChange={handleChange('password')}
                value={state.password}
                required
              />
            </div>
            <div className={classes.action}>
              <a href="/recover">Esqueci minha senha</a>
              <Button className={classes.button}>ENTRAR</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
