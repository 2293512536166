import React from 'react'

import { classNames } from '../../util/class-names'

interface Props {
  className?: string
  disabled?: boolean
  enabled?: boolean
  onClick?: (_: React.MouseEvent) => void
  outline?: boolean
  size?: 'lg' | 'sm'
  target?: string
  to?: string
  type?: 'button' | 'reset' | 'submit'
  variant?: 'danger' | 'dark' | 'info' | 'light' | 'link' | 'primary' | 'secondary' | 'success' | 'warning'
}

export const Button: React.FC<Props> = ({ className, disabled, enabled, outline, size, type, variant, ...props }) => {
  const klass = classNames(
    'btn',
    `btn${outline ? '-outline' : ''}-${variant || 'primary'}`,
    size && `btn-${size}`,
    className
  )
  const isDisabled = typeof enabled === 'boolean' ? !enabled : disabled
  return <button className={klass} type={type || 'button'} disabled={isDisabled} {...props} />
}
