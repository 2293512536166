import * as React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import { CalendarBlock } from './calendar-block/CalendarBlock'
import { CalendarView } from './calendar-view/CalendarView'
import { CalendarList } from './calendar-list/CalendarList'

export const Calendar: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    {/*<Route path={`${match.path}`} exact component={CalendarView} />*/}
    <Route path={`${match.path}`} exact component={CalendarList} />
    <Route path={`${match.path}/block`} component={CalendarBlock} />
  </Switch>
)
