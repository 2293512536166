import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

export function useSkipEffect(times: number, effect: EffectCallback, deps: DependencyList) {
  const count = useRef(0)
  useEffect(() => {
    if (++count.current > times) {
      return effect()
    }
  }, deps)
}
