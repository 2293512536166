import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'

import { AuthContextProvider } from './components/auth/AuthContext'

import { Routes } from './Routes'

export const App = () => (
  <BrowserRouter>
    <AuthContextProvider>
        <ChakraProvider>
            <Routes />
        </ChakraProvider>
    </AuthContextProvider>
  </BrowserRouter>
)
