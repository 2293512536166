import React, { useEffect, useState } from 'react'

import { Button } from 'shared/bootstrap/button'
import { SessionStatus } from 'shared/enum/session/SessionStatus'
import { FormCheck } from 'shared/form/FormCheck'
import { FormGroup } from 'shared/form/FormGroup'
import { FormTextArea } from 'shared/form/FormTextArea'
import { useTagCategory } from 'shared/hooks/data/tag-category'
import { Modal } from 'shared/modal'
import { Icon } from 'shared/ui/icon'
import { toFormData } from 'shared/util/form-data'

import classes from './SessionReportModal.module.scss'

interface SessionReportModalProps {
  cancel: boolean
  close: () => void
  session: { id: number }
  status?: SessionStatus
}

type SessionReportType = Pick<Model.SessionReport, 'additional_info' | 'cancel' | 'notes'>

export const SessionReportModal: React.FC<SessionReportModalProps> = ({ cancel, close, session }) => {
  const tagCategory = useTagCategory('session-report.cancel')
  const [status, setStatus] = useState<SessionStatus>()
  const [tag, setTag] = useState<Model.Tag>()
  const [value, setValue] = useState<SessionReportType>({
    additional_info: {},
    cancel: cancel,
    notes: ''
  })
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const body = toFormData({
      ...value,
      session: {
        id: session.id,
        status: status
      },
      tags: [{ id: tag?.id }]
    })
    fetch(`/api/session-reports`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          window.location.reload()
        } else {
          throw new Error()
        }
      })
      .catch(() => alert('Falha ao salvar alterações'))
  }
  useEffect(() => {
    if (cancel) {
      if (value.additional_info?.displacement) {
        setStatus(SessionStatus.CANCELED_DISPLACEMENT)
      } else {
        setStatus(SessionStatus.CANCELED_CHARGED)
      }
    } else {
      const handleLocation = ({ coords: location }: GeolocationPosition) => {
        setValue(({ additional_info, ...value }) => ({ ...value, additional_info: { ...additional_info, location } }))
      }
      navigator.geolocation.getCurrentPosition(handleLocation)
      setStatus(SessionStatus.PENDING)
    }
  }, [cancel, value])
  return (
    <Modal size="lg">
      <form onSubmit={handleSubmit}>
        <h3 className={classes.title}>
          {cancel ? 'Confirmar cancelamento de ensaio' : 'Confirmar realização de ensaio'}
        </h3>
        {!cancel && (
          <FormGroup label="Faltou algum ambiente?">
            <FormTextArea
              onChange={lacking_areas => setValue(value => ({ ...value, additional_info: { lacking_areas } }))}
              rows={2}
              value={value.additional_info?.lacking_areas}
            />
          </FormGroup>
        )}
        {cancel && (
          <FormGroup label="Motivo de cancelamento" required>
            <div className={classes.tags}>
              {tagCategory?.tags.map(({ id, name, description }) => {
                const selected = tag?.id === id
                const variant = selected ? 'dark' : 'secondary'
                const params = {
                  onClick: () => setTag({ id, name, description }),
                  outline: !selected
                }
                return (
                  <Button key={id} size="sm" variant={variant} {...params}>
                    {name}
                  </Button>
                )
              })}
            </div>
            {tag?.description && <span>{tag.description}</span>}
          </FormGroup>
        )}
        {cancel && (
          <FormCheck
            className={classes.check}
            onChange={displacement => setValue(value => ({ ...value, additional_info: { displacement } }))}>
            Cancelamento com deslocamento
          </FormCheck>
        )}
        {/*<FormGroup label="Problemas">
          <small className={classes.info}>
            Resuma os problemas ao realizar o ensaio em itens, como: "Não consegui contato com o responsável", "As
            chaves do imóvel não estavam disponíveis", "Acesso não liberado a áreas comuns", etc.
          </small>
          {value.tags.map((tag, i) => (
            <div key={i} className={classes.problem}>
              <FormText className={classes.input} onChange={handleTag(i)} value={tag.name} />
              <RoundButton className={classes.remove} size="sm">
                <Icon.Times />
              </RoundButton>
            </div>
          ))}
        </FormGroup>*/}
        <FormGroup className={classes.notes} label="Observações" required={cancel}>
          <small className={classes.info}>
            {cancel
              ? 'Informações adicionais do cancelamento do ensaio (obrigatório)'
              : 'Informações adicionais da realização do ensaio (opcional)'}
          </small>
          <FormTextArea
            onChange={notes => setValue(value => ({ ...value, notes }))}
            required={cancel}
            rows={5}
            value={value.notes}
          />
        </FormGroup>
        <div className={classes.footer}>
          <Button onClick={close} outline variant="secondary">
            CANCELAR
          </Button>
          <Button className={classes.submit} type="submit" variant="primary">
            <span>SALVAR</span>
            <Icon.CheckCircle />
          </Button>
        </div>
      </form>
    </Modal>
  )
}
