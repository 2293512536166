import React from 'react'

import { classNames } from 'shared/util/class-names'

import { useModalContainerContext } from './ModalContainer'

interface Props {
  className?: string
  title?: string
}

export const ModalHeader: React.FC<Props> = ({ className, title /*...props */ }) => {
  const context = useModalContainerContext()
  return (
    <div className={classNames('modal-header', className)}>
      {title && <h5 className="modal-title">{title}</h5>}
      <button className="btn-close" type="button" onClick={context?.current.close} />
    </div>
  )
}
