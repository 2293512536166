import React, { useState } from 'react'

import { FormUpload } from 'shared/form/FormUpload'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon'
import { toFormData } from 'shared/util/form-data'
import { parseAddress } from 'shared/util/google-maps'

import classes from './SessionDeliverableBlueprints.module.scss'

interface SessionDeliverableBlueprintsProps {
  product: Resource.SessionResourceProduct<Resource.SessionDeliverableBlueprint>
  category?: 'admin' | 'client' | 'provider'
  session: Resource.SessionResource
}

export const SessionDeliverableBlueprints: React.FC<SessionDeliverableBlueprintsProps> = ({
  category,
  product,
  session
}) => {
  const [products, setProducts] = useState(product.deliverables)
  const handleDelete = (id: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    fetch(`/api/sessions/${session.id}/${product.id}/blueprints/${id}`, { method: 'DELETE' }).then(response => {
      if (response.ok) {
        setProducts(products => [...products.filter(product => product.id !== id)])
      }
    })
  }
  const handleDownload = (event: React.MouseEvent) => {
    event.preventDefault()
    const url = `/api/sessions/${session.id}/${product.id}/blueprints/download`
    fetch(url, { method: 'POST' })
      .then(response => {
        if (response.ok) {
          return response.blob()
        } else {
          throw new Error(`Download failed`)
        }
      })
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `Plantas ID ${session.id}`
        if (session.vertical_data?.reference) {
          a.download += ` - ID ${session.vertical_data?.reference}`
        }
        const address = parseAddress(session.address?.address_components)
        if (address.has('street') && address.has('city') && address.has('state')) {
          a.download += ` - ${address.get('street')}, ${address.get('city')} - ${address.get('state')}`
        }
        a.download += '.zip'
        a.click()
      })
  }
  const handleUpload = (files: File[]) => {
    const uploads = files
      .filter(file => file.size <= 10 * 1024 * 1024)
      .map(file => {
        const body = toFormData({ file })
        return fetch(`/api/sessions/${session.id}/${product.id}/blueprints`, { body, method: 'POST' })
          .then(response => response.json())
          .then(({ data }) => setProducts(products => [...products, data]))
      })
    if (uploads.length < files.length) {
      alert('Apenas arquivos de até 10 MB são permitidos')
    }
  }
  return (
    <div className={classes.content}>
      <div className={classes.deliverables}>
        {category !== 'client' && (
          <FormUpload accept=".dwg,.pdf,.png" multiple onChange={handleUpload}>
            <span>Fazer upload das plantas (formatos DWG, PDF ou PNG) de até 10 MB</span>
          </FormUpload>
        )}
        {products.map(product => (
          <div key={product.id} className={classes.product}>
            {product.file_extension === 'pdf' ? (
              <Icon.FilePdf className={classes.icon} />
            ) : (
              <Icon.File className={classes.icon} />
            )}
            <span className={classes.file}>{product.file_name}</span>
            {category !== 'client' && (
              <button className={classes.remove} onClick={handleDelete(product.id)}>
                <Icon.Times />
              </button>
            )}
          </div>
        ))}
        <Button onClick={handleDownload}>Baixar arquivos</Button>
      </div>
    </div>
  )
}
