import { useEffect, useState } from 'react'

import { toFormData } from '../../util/form-data'

export function useTagCategories(params: Http.Params) {
  const [tagCategories, setTagCategories] = useState<Model.TagCategory[]>()
  useEffect(() => {
    setTagCategories(undefined)
    const request = {
      body: toFormData(params),
      method: 'POST'
    }
    fetch(`/api/tag-categories/search`, request)
      .then(response => response.json())
      .then(({ data }) => {
        setTagCategories(data)
      })
  }, [params])
  return tagCategories
}

export function useTagCategory(key: number | string) {
  const [tagCategory, setTagCategory] = useState<Model.TagCategory>()
  useEffect(() => {
    setTagCategory(undefined)
    fetch(`/api/tag-categories/${key}`)
      .then(response => response.json())
      .then(({ data }) => {
        setTagCategory(data)
      })
  }, [key])
  return tagCategory
}
