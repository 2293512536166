import React, { createContext, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { classNames } from '../../util/class-names'

import classes from './ModalContainer.module.scss'
export interface ModalContainerRef<T extends object> {
  close: () => void
  data: T
  open: (data?: object) => void
}
interface ModalContainerProps {
  backDropClick?: (_: React.MouseEvent) => void
  backDropClose?: boolean
  children: React.ReactElement
  className?: string
}

const ModalContext = createContext<ModalContainerRef<object>>(undefined!)
const ModalContainerContext = createContext<{ current: ModalContainerRef<object> }>(undefined!)

export function useModalContext<T extends object>() {
  return useContext(ModalContext)! as ModalContainerRef<T>
}

export function useModalContainerContext<T extends object>() {
  return useContext(ModalContainerContext) as { current: ModalContainerRef<T> }
}

export const ModalContainer = forwardRef<ModalContainerRef<object>, ModalContainerProps>(
  ({ backDropClick, backDropClose, className, children }, ref) => {
    const [opened, setOpened] = useState(false)
    const [props, setProps] = useState<any>({})
    // const [data, setData] = useState({})
    const actions = useRef({
      data: {},
      close: () => {
        setOpened(false)
      },
      open: (data?: object) => {
        actions.current.data = data || {}
        setProps(data || {})
        setOpened(true)
      }
    })
    useImperativeHandle(ref, () => actions.current)
    useEffect(() => {
      if (opened) {
        document.body.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`
        requestAnimationFrame(() => {
          document.body.classList.add('modal-open')
        })
      } else {
        document.body.classList.remove('modal-open')
        document.body.style.paddingRight = ''
      }
    }, [opened])
    const handleClick = (event: React.MouseEvent) => {
      event.persist()
      console.log(event)
      backDropClick?.(event)
      if (!event.defaultPrevented && backDropClose !== false) {
        setOpened(false)
      }
    }
    const klass = classNames(classes.container, opened && classes.opened, className)
    return (
      <ModalContext.Provider value={actions.current}>
        <ModalContainerContext.Provider value={actions}>
          <div className={klass} onClick={handleClick}>
            {React.cloneElement(children, props)}
          </div>
        </ModalContainerContext.Provider>
      </ModalContext.Provider>
    )
  }
)
