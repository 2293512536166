import * as React from 'react'
import {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {FormGroup} from 'shared/bootstrap/form/FormGroup'
import {FormTextArea} from 'shared/form/FormTextArea'
import {Button} from 'shared/ui/button/Button'
import {useModal} from 'shared/bootstrap/modal'
import {CalendarBlockConfirm} from '../calendar-block/CalendarBlockConfirm'
import {InputDateRange} from 'shared/bootstrap/form/InputDateRange'
import {toFormData} from 'shared/util/form-data'
import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast,
    VStack
} from '@chakra-ui/react'

import classes from './CalendarBlock.module.scss'
import {FrequencyBlock, getFrequencyBlockDescription} from "../../../shared/enum/provider/calendar/FrequencyBlock";

export const CalendarBlock: React.FC = () => {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const history = useHistory()

    const [isChecked, settIsChecked] = useState(false)
    const [text, setText] = useState<string>('')
    const [date, setDate] = useState<[string, string]>(['', ''])
    const [timeStart, setTimeStart] = useState<any>()
    const [timeEnd, setTimeEnd] = useState<any>()
    const [repeat, setRepeat] = useState('0')
    const [errors, setErrors] = useState<any>()

    const confirmModel = useModal(CalendarBlockConfirm)

    const blockFrequency = [
        FrequencyBlock.DOES_NOT_REPEAT,
        FrequencyBlock.WEEKLY
    ]

    useEffect(() => {
        if (isChecked) {
            setTimeStart('00:00')
            setTimeEnd('23:59')
        }
    }, [isChecked, date])


    const handleConfirmModel = (event: any) => {
        event.preventDefault()
        onClose()
        // confirmModel.current?.open()

        const body = toFormData({
            dateStart: date[0],
            dateEnd: date[1],
            timeStart,
            timeEnd,
            reason: text,
            frequency: repeat
        })


        fetch('/api/provider/calendar/block', {body, method: 'POST'})
            .then(response => response.json())
            .then(response => {
                if (response?.success){
                    setText('')
                    if (!isChecked) {
                        setTimeStart('')
                        setTimeEnd('')
                    }

                    setRepeat('0')
                    setErrors({})

                    toast({
                        title: 'Bloqueio criado com sucesso.',
                        position: 'top-right',
                        // description: "We've created your account for you.",
                        status: 'success',
                        duration: 8000,
                        isClosable: true,
                    })

                    history.push('/calendar');

                }else{
                    if (response?.error){
                        toast({
                            title: 'Não foi possivel criar o bloqueio.',
                            position: 'top-right',
                            description: response?.message,
                            status: 'error',
                            duration: 9500,
                            isClosable: true,
                        })
                    }
                    setErrors(response)
                }

            }
        )
    }

    const handleCheck = (event: any) => {
        settIsChecked(event.target.checked)
    }
    const handleDate = (event: any) => {
        setDate(event)
    }
    const handleTimeStart = (event: any) => {
        setTimeStart(event.target.value)
    }
    const handleTimeEnd = (event: any) => {
        setTimeEnd(event.target.value)
    }

    const handleRepeat = (event: any) => {
        setRepeat(event.target.value)
    }

    const handleText = (event: any) => {
        setText(event)
    }

    return (
        <div className={classes.container}>
            <div className={classes.backButton}>
                <Link to="/calendar">
                    <img src="/img/BackButton.svg" alt="" className={classes.p8}/>
                </Link>
                <strong>Voltar</strong>
            </div>

            <div className={classes.my16}>
                <h2 className={classes.h2}>Bloqueie um horário na sua agenda</h2>
                <p>Procure adicionar bloqueios com no mínimo 72 horas de antecedência.</p>
            </div>

            <form>
                <div className={classes.flex}>
                    <FormGroup label="Data à bloquear" className={classes.date}>
                        <InputDateRange onChange={handleDate}/>
                        {errors?.dateStart &&  <p className={classes.errors}>{errors?.dateStart}</p>
                            || errors?.dateEnd &&  <p className={classes.errors}>{errors?.dateEnd}</p>
                            || errors?.date &&  <p className={classes.errors}>{errors?.date}</p>}
                    </FormGroup>
                    {!isChecked ? (
                        <FormGroup label="Horário" className={classes.my16}>
                            <br/>
                            <input
                                type="time"
                                id="timeStart"
                                name="timeStart"
                                min="07:00"
                                max="19:00"
                                onChange={handleTimeStart}
                                step="1800"
                                value={timeStart}
                                className={classes.mr4}></input>
                            <input type="time" id="timeEnd" name="timeEnd" min="07:00" max="19:00" value={timeEnd} step="1800"
                                   onChange={handleTimeEnd}></input>
                            {errors?.timeStart &&  <p className={classes.errors}>{errors?.timeStart}</p>
                                || errors?.timeEnd &&  <p className={classes.errors}>{errors?.timeEnd}</p>}
                        </FormGroup>
                    ) : (
                        ''
                    )}
                </div>

                <FormGroup className={classes.my8}>
                    <div>
                        <input type="checkbox" name="allDay" id="allDay" onChange={handleCheck}/>
                        <label htmlFor="allDay" className={classes.ml4}>
                            Dia inteiro
                        </label>
                    </div>
                </FormGroup>

                <FormGroup label="Frequência do bloqueio" className={classes.my16}>
                    <select className={classes.select} value={repeat} onChange={handleRepeat}>
                        {blockFrequency.map(block =>
                            <option value={block} key={block}>
                                {getFrequencyBlockDescription(block)}
                            </option>
                        )
                        }
                    </select>
                </FormGroup>

                {/*<hr className={classes.my16}/>*/}
                <FormGroup label="Motivo do bloqueio" className={classes.my16}>
                    <FormTextArea required value={text} onChange={handleText}/>
                    {errors?.reason &&  <p className={classes.errors}>{errors?.reason}</p>}
                </FormGroup>

                {/*<hr className={classes.my16}/>*/}

                <div className={classes.centerAll}>
                    <Button onClick={(event) => {
                        if(text.length <= 0 || !date[0] || !date[1]) return false;
                        event.preventDefault()
                        onOpen()
                    }} size="sm" className={classes.my16}>
                        Criar um bloqueio na agenda
                    </Button>
                </div>
            </form>

            <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirmar bloqueio</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={'4'}>
                            <h1>
                                CONFIRMAR BLOQUEIO NA AGENDA DE:
                            </h1>

                            <VStack spacing={'1'}>
                            <h1>
                                {date[0] && timeStart && new Date(date[0]+':'+timeStart).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}
                            </h1>
                                <h1>até</h1>
                            <h1>
                                {date[1] && timeEnd && new Date(date[1]+':'+timeEnd).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}
                            </h1>

                            </VStack>
                        </VStack>

                    </ModalBody>

                    <ModalFooter>
                        <Flex justify={'space-between'} w={'100%'}>
                            <Button onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button type={'button'} onClick={handleConfirmModel}>Criar o bloqueio</Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}
