import React, { useEffect, useState } from 'react'

type SetMeta<T> = React.Dispatch<React.SetStateAction<T | undefined>>

export function useSessions<M extends Http.ResponseMeta>(
  params?: Http.Params,
  paging?: Http.Pagination,
  setMeta?: SetMeta<M>
) {
  const [sessions, setSessions] = useState<Model.Session[]>()
  useEffect(() => {
    setSessions(undefined)
    const abort = new AbortController()
    const request: RequestInit = {
      body: JSON.stringify(params || {}),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      signal: abort.signal
    }
    const search = new URLSearchParams()
    search.set('v', '2') // temporário
    if (paging) {
      search.set('page', `${paging.page}`)
      search.set('length', `${paging.length}`)
    }
    fetch(`/api/sessions/search?${search.toString()}`, request)
      .then(response => response.json())
      .then(({ data, meta }) => {
        setSessions(data)
        setMeta?.(meta)
      })
      .catch(error => {
        if (error instanceof DOMException && error.code === DOMException.ABORT_ERR) {
          return true
        } else {
          throw error
        }
      })
    return () => abort.abort()
  }, [paging, params, setMeta])
  return sessions
}

export function useSession(key: Model.KeyOrToken) {
  const [session, setSession] = useState<Resource.SessionResource>()
  useEffect(() => {
    const url = 'token' in key ? `/api/sessions/${key.token}` : `/api/sessions/${key.id}`
    fetch(url)
      .then(response => response.json())
      .then(({ data }) => setSession(data))
  }, [key])
  return session
}
