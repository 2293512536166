import React, { Fragment } from 'react'
import { isTomorrow, parseISO } from 'date-fns'

import { Card, CardBody } from 'shared/bootstrap/card'
import { ItemWorkType } from 'shared/enum/item'
import { Button } from 'shared/ui/button/Button'
import { RoundButton } from 'shared/ui/button/RoundButton'
// import { Card } from 'shared/ui/card/Card'
import { Icon } from 'shared/ui/icon'
import { classNames } from 'shared/util/class-names'
import { formatISO } from 'shared/util/date'
import { phoneToLink, phoneToWhatsApp } from 'shared/util/phone-number'

import { useAuth } from 'components/auth/AuthContext'

import classes from './SessionScheduled.module.scss'

interface SessionScheduledProps {
  session: Resource.SessionResource
}
// 📸🏠
const whatsAppConfirmation = (session: Resource.SessionResource, user: Model.ProviderUser, deliverable?: string) => `
Olá ${session.scheduling_responsible_info.name || session.responsible_information.name}, tudo bem?

Sou fotógrafo(a) parceiro ${session.client.name} responsável por fazer o ensaio${deliverable && ` de ${deliverable}`}.

A sessão está agendada para:
Dia ${formatISO(session.scheduled_for!, 'dd/MM')}, às ${formatISO(session.scheduled_for!, 'HH:mm')}
Duração média de ${session.duration / 60} minutos.
Endereço agendado: ${session.address?.formatted_address || '-'}

Meu nome completo é ${user.provider.name} e meu RG é ${user.provider.rg}, caso seja necessário avisar na portaria.

Tudo certo para o ensaio de amanhã neste endereço?

Qualquer dúvida, fico a disposição!
Muito obrigado pela atenção e aguardo retorno.`

export const SessionScheduled: React.FC<SessionScheduledProps> = ({ session }) => {
  const auth = useAuth()
  const responsible = session.scheduling_responsible_info.name
  const phone = session.scheduling_responsible_info.phone || null
  const hasCheckListLink = typeof session.additional_info.checklist_link === 'string'
  const hasDeliveryLink = typeof session.additional_info.delivery_link === 'string'
  const phoneLink = phoneToLink(phone)
  const whatsAppLink = phoneToWhatsApp(phone)
  const deliveryItem = session.products.find(({ work_type }) => work_type === ItemWorkType.LOCAL)?.item?.item?.title
  const whatsAppConfirmationLink = phoneToWhatsApp(phone, whatsAppConfirmation(session, auth!, deliveryItem).trim())
  const whatsAppMessageLink = phoneToWhatsApp(phone, 'Olá, já estou a caminho!')
  const handleLinkCopy = (url: string) => () => navigator.clipboard.writeText(url)
  const handleLinkOpen = (url: string) => () => window.open(url)
  const scheduledFor = parseISO(session.scheduled_for!)
  return (
    <div className={classes.container}>
      {responsible && (
        <Card className={classNames(classes.card, classes.contact)} shadow>
          <CardBody>
            <p className={classes.title}>Falar com o responsável</p>
            <p className={classes.name}>{responsible || '-'}</p>
            <Button className={classes.button} outline href={phoneLink}>
              <Icon.PhoneAlt />
              <span>LIGAR</span>
            </Button>
            {isTomorrow(scheduledFor) ? (
              <Button className={classes.button} outline href={whatsAppConfirmationLink}>
                <Icon.Whatsapp />
                <span>CONFIRMAR ENSAIO PARA AMANHÃ</span>
              </Button>
            ) : (
              <Button className={classes.button} outline href={whatsAppMessageLink}>
                <Icon.Whatsapp />
                <span>AVISAR QUE ESTOU A CAMINHO</span>
              </Button>
            )}
            <Button className={classes.button} outline href={whatsAppLink}>
              <Icon.Whatsapp />
              <span>ENVIAR MENSAGEM</span>
            </Button>
            <p className={classes.whatsAppMessage}>
              Envie uma mensagem pronta informando que você está a caminho e a sua localização em tempo real.
            </p>
          </CardBody>
        </Card>
      )}
      <Card className={classNames(classes.card, classes.extra)} shadow>
        <CardBody>
          <p className={classes.title}>Informações extras</p>
          <p className={classes.info}>{session.provider_info?.extra || '-'}</p>
          <p className={classes.title}>Informações adicionais</p>
          <p className={classes.info}>{session.vertical_data?.additional_info || '-'}</p>
          {hasDeliveryLink && (
            <div className={classes.deliveryLink}>
              <span className={classes.label}>Link de Entrega:</span>
              {session.additional_info?.delivery_link ? (
                <Fragment>
                  <a
                    className={classes.link}
                    href={session.additional_info.delivery_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {session.additional_info.delivery_link}
                  </a>
                  <RoundButton type="button" onClick={handleLinkCopy(session.additional_info.delivery_link)}>
                    <Icon.Clipboard />
                  </RoundButton>
                  <RoundButton type="button" onClick={handleLinkOpen(session.additional_info.delivery_link)}>
                    <Icon.ExternalLinkAlt />
                  </RoundButton>
                </Fragment>
              ) : (
                '-'
              )}
            </div>
          )}
          {hasCheckListLink && (
            <div className={classes.deliveryLink}>
              <span className={classes.label}>Link de Check-List:</span>
              {session.additional_info?.checklist_link ? (
                <Fragment>
                  <a
                    className={classes.link}
                    href={session.additional_info.checklist_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {session.additional_info.checklist_link}
                  </a>
                  <RoundButton type="button" onClick={handleLinkCopy(session.additional_info.checklist_link)}>
                    <Icon.Clipboard />
                  </RoundButton>
                  <RoundButton type="button" onClick={handleLinkOpen(session.additional_info.checklist_link)}>
                    <Icon.ExternalLinkAlt />
                  </RoundButton>
                </Fragment>
              ) : (
                '-'
              )}
            </div>
          )}
        </CardBody>
      </Card>
      <Card className={classNames(classes.card, classes.schedulingResponsible)} shadow>
        <CardBody className={classes.body}>
          <p className={classes.title}>Quem irá receber o fotógrafo</p>
          <Icon.User />
          <span>{responsible || '-'}</span>
          <Icon.Envelope />
          <span>{session.scheduling_responsible_info.email || '-'}</span>
          <Icon.PhoneAlt />
          <span>{phone || '-'}</span>
          {session.vertical_data?.keys && (
            <Fragment>
              <Icon.Key />
              <span>{session.vertical_data.keys}</span>
            </Fragment>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
