import React from 'react'

import { classNames } from 'shared/util/class-names'

import { useFormGroupContext } from './FormGroupNew'

export interface Props {
  className?: string
  initial?: string | null
  maxLength?: number
  onChange?: (_: string) => void
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  type?: 'email' | 'password' | 'text' | 'url'
  value?: string
}

export const Input: React.FC<Props> = ({ className, initial, onChange, ...props }) => {
  const formGroup = useFormGroupContext()
  const params = {
    defaultValue: initial || undefined,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value)
    }
  }
  return <input className={classNames('form-control', className)} id={formGroup?.id} {...props} {...params} />
}
