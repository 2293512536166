import React from 'react'

import { classNames } from 'shared/util/class-names'

interface Props {
  className?: string
}

export const InputGroupText: React.FC<Props> = ({ className, ...props }) => (
  <span className={classNames('input-group-text', className)} {...props} />
)
