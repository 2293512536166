import React from 'react'

import { useFormGroupContext } from './FormGroupNew'

export interface Props {
  initial?: string | null
  maxLength?: number
  onChange?: (_: string) => void
  placeholder?: string
  required?: boolean
  rows?: number
  value?: string
}

export const InputTextArea: React.FC<Props> = ({ initial, onChange, ...props }) => {
  const formGroup = useFormGroupContext()
  const params = {
    defaultValue: initial || undefined,
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(event.target.value)
    }
  }
  return <textarea className="form-control" id={formGroup?.id} {...props} {...params} />
}
