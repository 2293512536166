import { tap } from './helpers'

const ADDRESS_COMPONENTS = [
  'administrative_area_level_1',
  'administrative_area_level_2',
  'postal_code',
  'route',
  'street_number',
  'sublocality'
] as const

type AddressComponent = typeof ADDRESS_COMPONENTS[number]

export function parseAddressComponents(components?: google.maps.GeocoderAddressComponent[]) {
  const map = new Map<AddressComponent, string>()
  return components?.reduce((map, component) => {
    const type = ADDRESS_COMPONENTS.find(type => component.types.includes(type))
    return type ? map.set(type, component.long_name) : map
  }, map)
}

const ADDRESS_PARTS = {
  state: ['administrative_area_level_1', 'short_name'],
  city: ['administrative_area_level_2'],
  neighborhood: ['sublocality'],
  street: ['route'],
  number: ['street_number'],
  postal_code: ['postal_code']
} as const

type AddressKey = keyof typeof ADDRESS_PARTS

export function parseAddress(components?: google.maps.GeocoderAddressComponent[]) { 
  return tap(new Map<AddressKey, string>(), address => {
    components?.forEach(component => {
      const entry = Object.entries(ADDRESS_PARTS).find(([, [type]]) => {
        return component.types.includes(type)
      })
      if (entry) {
        const [key, [, prop = 'long_name']] = entry as [AddressKey, [string, keyof google.maps.GeocoderAddressComponent]]
        address.set(key, component[prop] as string)
      }
    })
  })
}

export function toGoogleMapsUrl(location?: google.maps.LatLng) {
  if (location) {
    const lat = Number(location?.lat)
    const lng = Number(location?.lng)
    if (!isNaN(lat) && !isNaN(lng)) {
      return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    }
  }
  return ''
}
